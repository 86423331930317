export default function ExcludedCnae({ code }) {
  return (
    <div>
      <div className="error-cnae">Insira um código CNAE válido</div>
      <div>
        <div>
          <h3>{code.cnae}</h3>
        </div>
        <div>
          <p>{code.cnae_desc}</p>
        </div>
      </div>
    </div>
  )
}
