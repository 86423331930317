export const cnae_codes = [
  {
    cnae: '6201-5/00',
    cnae_desc: 'Desenvolvimento  de  programas  de  computador sob encomenda',
    item: '01.01',
    item_desc: 'Análise e desenvolvimento de sistemas.',
  },
  {
    cnae: '6201-5/00',
    cnae_desc: 'Desenvolvimento  de  programas  de  computador sob encomenda',
    item: '01.02',
    item_desc: 'Programação.',
  },
  {
    cnae: '6190-6/01',
    cnae_desc:
      'Provedores      de      acesso      às      redes      de comunicações',
    item: '01.03',
    item_desc: 'Processamento de dados e congêneres.',
  },
  {
    cnae: '1830-0/03',
    cnae_desc: 'REPRODUCAO       DE       SOFTWARE       EM QUALQUER SUPORTE',
    item: '01.03',
    item_desc: 'Processamento de dados e congêneres.',
  },
  {
    cnae: '6311-9/00',
    cnae_desc:
      'Tratamento  de  dados,  provedores  de  serviços de   aplicação   e   serviços   de   hospedagem   na internet',
    item: '01.03',
    item_desc: 'Processamento de dados e congêneres.',
  },
  {
    cnae: '6201-5/00',
    cnae_desc: 'Desenvolvimento  de  programas  de  computador sob encomenda',
    item: '01.04',
    item_desc:
      'Elaboração   de   programas   de   computadores,   inclusive   de   jogos eletrônicos.',
  },
  {
    cnae: '6202-3/00',
    cnae_desc:
      'Desenvolvimento e licenciamento de programas de computador customizáveis',
    item: '01.04',
    item_desc:
      'Elaboração   de   programas   de   computadores,   inclusive   de   jogos eletrônicos.',
  },
  {
    cnae: '6203-1/00',
    cnae_desc:
      'Desenvolvimento e licenciamento de programas de computador não-customizáveis',
    item: '01.04',
    item_desc:
      'Elaboração   de   programas   de   computadores,   inclusive   de   jogos eletrônicos.',
  },
  {
    cnae: '6202-3/00',
    cnae_desc:
      'Desenvolvimento e licenciamento de programas de computador customizáveis',
    item: '01.05',
    item_desc:
      'Licenciamento   ou   cessão   de   direito   de   uso   de   programas   de computação.',
  },
  {
    cnae: '6204-0/00',
    cnae_desc: 'Consultoria em tecnologia da informação',
    item: '01.06',
    item_desc: 'Assessoria e consultoria em informática.',
  },
  {
    cnae: '6209-1/00',
    cnae_desc:
      'Suporte  técnico,  manutenção  e  outros  serviços em tecnologia da informação',
    item: '01.07',
    item_desc:
      'Suporte  técnico  em  informática,  inclusive  instalação,  configuração  e manutenção de programas de computação e bancos de dados.',
  },
  {
    cnae: '6201-5/00',
    cnae_desc: 'Desenvolvimento  de  programas  de  computador sob encomenda',
    item: '01.08',
    item_desc:
      'Planejamento,   confecção,   manutenção   e   atualização   de   páginas eletrônicas.',
  },
  {
    cnae: '6319-4/00',
    cnae_desc:
      'Portais,   provedores   de   conteúdo   e   outros serviços de informação na internet',
    item: '01.08',
    item_desc:
      'Planejamento,   confecção,   manutenção   e   atualização   de   páginas eletrônicas.',
  },
  {
    cnae: '7210-0/00',
    cnae_desc:
      'Pesquisa  e  desenvolvimento  experimental  em ciências físicas e naturais',
    item: '02.01',
    item_desc: 'Serviços de pesquisas e desenvolvimento de qualquer natureza.',
  },
  {
    cnae: '7220-7/00',
    cnae_desc:
      'Pesquisa  e  desenvolvimento  experimental  em ciências sociais e humanas',
    item: '02.01',
    item_desc: 'Serviços de pesquisas e desenvolvimento de qualquer natureza.',
  },
  {
    cnae: '8211-3/00',
    cnae_desc:
      'Serviços   combinados   de   escritório   e   apoio administrativo',
    item: '03.03',
    item_desc:
      'Exploração  de  salões  de  festas,  centro  de  convenções,  escritórios virtuais,  stands,  quadras  esportivas,  estádios,  auditórios,  casas  de espetáculos,  parques  de  diversões,  canchas  e  congêneres,  para realização de eventos ou negócios de qualquer natureza',
  },
  {
    cnae: '8230-0/02',
    cnae_desc: 'Casas de festas e eventos',
    item: '03.03',
    item_desc:
      'Exploração  de  salões  de  festas,  centro  de  convenções,  escritórios virtuais,  stands,  quadras  esportivas,  estádios,  auditórios,  casas  de espetáculos,  parques  de  diversões,  canchas  e  congêneres,  para realização de eventos ou negócios de qualquer natureza',
  },
  {
    cnae: '9003-5/00',
    cnae_desc:
      'Gestão    de    espaços    para    artes    cênicas, espetáculos e outras atividades artísticas',
    item: '03.03',
    item_desc:
      'Exploração  de  salões  de  festas,  centro  de  convenções,  escritórios virtuais,  stands,  quadras  esportivas,  estádios,  auditórios,  casas  de espetáculos,  parques  de  diversões,  canchas  e  congêneres,  para realização de eventos ou negócios de qualquer natureza',
  },
  {
    cnae: '3520-4/02',
    cnae_desc: 'Distribuição de combustíveis gasosos por redes urbanas',
    item: '03.04',
    item_desc:
      'Locação,    sublocação,    arrendamento,    direito   de   passagem    ou permissão de uso, compartilhado ou não, de ferrovia, rodovia, postes, cabos, dutos e condutos de qualquer natureza.',
  },
  {
    cnae: '3514-0/00',
    cnae_desc: 'Distribuição de energia elétrica',
    item: '03.04',
    item_desc:
      'Locação,    sublocação,    arrendamento,    direito   de   passagem    ou permissão de uso, compartilhado ou não, de ferrovia, rodovia, postes, cabos, dutos e condutos de qualquer natureza.',
  },
  {
    cnae: '4911-6/00',
    cnae_desc: 'Transporte ferroviário de carga',
    item: '03.04',
    item_desc:
      'Locação,    sublocação,    arrendamento,    direito   de   passagem    ou permissão de uso, compartilhado ou não, de ferrovia, rodovia, postes, cabos, dutos e condutos de qualquer natureza.',
  },
  {
    cnae: '5221-4/00',
    cnae_desc:
      'Concessionárias  de  rodovias,  pontes,  túneis  e serviços relacionados',
    item: '03.04',
    item_desc:
      'Locação,    sublocação,    arrendamento,    direito   de   passagem    ou permissão de uso, compartilhado ou não, de ferrovia, rodovia, postes, cabos, dutos e condutos de qualquer natureza.',
  },
  {
    cnae: '4399-1/02',
    cnae_desc:
      'Montagem   e   desmontagem   de   andaimes   e outras estruturas temporárias',
    item: '03.05',
    item_desc:
      'Cessão de andaimes,  palcos,  coberturas e  outras estruturas  de uso temporário.',
  },
  {
    cnae: '4399-1/04',
    cnae_desc:
      'Serviços    de    operação    e    fornecimento    de equipamentos  para  transporte  e  elevação  de cargas e pessoas para uso em obras',
    item: '03.05',
    item_desc:
      'Cessão de andaimes,  palcos,  coberturas e  outras estruturas  de uso temporário.',
  },
  {
    cnae: '7732-2/02',
    cnae_desc: 'Aluguel de andaimes',
    item: '03.05',
    item_desc:
      'Cessão de andaimes,  palcos,  coberturas e  outras estruturas  de uso temporário.',
  },
  {
    cnae: '7739-0/03',
    cnae_desc:
      'Aluguel    de    palcos,    coberturas    e    outras estruturas de uso temporário, exceto andaimes',
    item: '03.05',
    item_desc:
      'Cessão de andaimes,  palcos,  coberturas e  outras estruturas  de uso temporário.',
  },
  {
    cnae: '8630-5/02',
    cnae_desc:
      'Atividade   médica   ambulatorial   com   recursos para realização de exames complementares',
    item: '04.01',
    item_desc: 'Medicina e biomedicina.',
  },
  {
    cnae: '8630-5/03',
    cnae_desc:
      'Atividade     médica     ambulatorial     restrita     a consultas',
    item: '04.01',
    item_desc: 'Medicina e biomedicina.',
  },
  {
    cnae: '8630-5/99',
    cnae_desc:
      'Atividades     de     atenção     ambulatorial     não especificadas anteriormente',
    item: '04.01',
    item_desc: 'Medicina e biomedicina.',
  },
  {
    cnae: '8640-2/01',
    cnae_desc: 'Laboratórios de anatomia patológica e citológica',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/02',
    cnae_desc: 'Laboratórios clínicos',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/04',
    cnae_desc: 'Serviços de tomografia',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/05',
    cnae_desc:
      'Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/06',
    cnae_desc: 'Serviços de ressonância magnética',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/07',
    cnae_desc:
      'Serviços de diagnóstico por imagem sem uso de radiação      ionizante,      exceto      ressonância magnética',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/08',
    cnae_desc:
      'Serviços  de  diagnóstico  por  registro  gráfico  - ECG, EEG e outros exames análogos',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/09',
    cnae_desc:
      'Serviços  de  diagnóstico  por  métodos  ópticos  - endoscopia e outros exames análogos',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/10',
    cnae_desc: 'Serviços de quimioterapia',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/11',
    cnae_desc: 'Serviços de radioterapia',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8640-2/99',
    cnae_desc:
      'Atividades   de   serviços   de   complementação diagnóstica   e   terapêutica   não   especificadas anteriormente',
    item: '04.02',
    item_desc:
      'Análises    clínicas,    patologia,    eletricidade    médica,    radioterapia, quimioterapia,  ultra  sonografia,  ressonância  magnética,  radiologia, tomografia e congêneres.',
  },
  {
    cnae: '8610-1/01',
    cnae_desc:
      'Atividades   de   atendimento   hospitalar,   exceto pronto-socorro  e  unidades  para  atendimento  a urgências',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8610-1/02',
    cnae_desc:
      'Atividades de atendimento em  pronto-socorro e unidades    hospitalares    para    atendimento    a urgências',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8630-5/01',
    cnae_desc:
      'Atividade   médica   ambulatorial   com   recursos para realização de procedimentos cirúrgicos',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8630-5/02',
    cnae_desc:
      'Atividade   médica   ambulatorial   com   recursos para realização de exames complementares',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8630-5/06',
    cnae_desc: 'Serviços de vacinação e imunização humana',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8640-2/01',
    cnae_desc: 'Laboratórios de anatomia patológica e citológica',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8640-2/02',
    cnae_desc: 'Laboratórios clínicos',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8640-2/03',
    cnae_desc: 'Serviços de diálise e nefrologia',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8640-2/13',
    cnae_desc: 'Serviços de litotripsia',
    item: '04.03',
    item_desc:
      'Hospitais,  clínicas,  laboratórios,  sanatórios,  manicômios,  casas  de saúde, prontos socorros, ambulatórios e congêneres.',
  },
  {
    cnae: '8650-0/99',
    cnae_desc:
      'Atividades  de  profissionais  da  área  de  saúde não especificadas anteriormente',
    item: '04.04',
    item_desc: 'Instrumentação cirúrgica.',
  },
  {
    cnae: '8690-9/01',
    cnae_desc:
      'Atividades      de      práticas      integrativas      e complementares em saúde humana',
    item: '04.05',
    item_desc: 'Acupuntura.',
  },
  {
    cnae: '8690-9/03',
    cnae_desc: 'Atividades de acumputura',
    item: '04.05',
    item_desc: 'Acupuntura.',
  },
  {
    cnae: '8650-0/01',
    cnae_desc: 'Atividades de enfermagem',
    item: '04.06',
    item_desc: 'Enfermagem, inclusive serviços auxiliares.',
  },
  {
    cnae: '4771-7/02',
    cnae_desc:
      'Comércio  varejista  de  produtos  farmacêuticos, com manipulação de fórmulas',
    item: '04.07',
    item_desc: 'Serviços farmacêuticos',
  },
  {
    cnae: '4771-7/03',
    cnae_desc: 'Comércio  varejista  de  produtos  farmacêuticos homeopáticos',
    item: '04.07',
    item_desc: 'Serviços farmacêuticos',
  },
  {
    cnae: '8650-0/04',
    cnae_desc: 'Atividades de fisioterapia',
    item: '04.08',
    item_desc: 'Terapia ocupacional, fisioterapia e fonoaudiologia.',
  },
  {
    cnae: '8650-0/05',
    cnae_desc: 'Atividades de terapia ocupacional',
    item: '04.08',
    item_desc: 'Terapia ocupacional, fisioterapia e fonoaudiologia.',
  },
  {
    cnae: '8650-0/06',
    cnae_desc: 'Atividades de fonoaudiologia',
    item: '04.08',
    item_desc: 'Terapia ocupacional, fisioterapia e fonoaudiologia.',
  },
  {
    cnae: '8640-2/12',
    cnae_desc: 'Serviços de hemoterapia',
    item: '04.09',
    item_desc:
      'Terapias   de   qualquer   espécie   destinadas   ao   tratamento   físico, orgânico e mental.',
  },
  {
    cnae: '8690-9/01',
    cnae_desc:
      'Atividades      de      práticas      integrativas      e complementares em saúde humana',
    item: '04.09',
    item_desc:
      'Terapias   de   qualquer   espécie   destinadas   ao   tratamento   físico, orgânico e mental.',
  },
  {
    cnae: '8640-2/99',
    cnae_desc:
      'Atividades   de   serviços   de   complementação diagnóstica   e   terapêutica   não   especificadas anteriormente',
    item: '04.09',
    item_desc:
      'Terapias   de   qualquer   espécie   destinadas   ao   tratamento   físico, orgânico e mental.',
  },
  {
    cnae: '8650-0/07',
    cnae_desc:
      'Atividades   de   terapia   de   nutrição   enteral   e parenteral',
    item: '04.09',
    item_desc:
      'Terapias   de   qualquer   espécie   destinadas   ao   tratamento   físico, orgânico e mental.',
  },
  {
    cnae: '8690-9/99',
    cnae_desc:
      'Outras  atividades  de  atenção  à  saúde  humana não especificadas anteriormente',
    item: '04.09',
    item_desc:
      'Terapias   de   qualquer   espécie   destinadas   ao   tratamento   físico, orgânico e mental.',
  },
  {
    cnae: '8650-0/02',
    cnae_desc: 'Atividades de profissionais da nutrição',
    item: '04.10',
    item_desc: 'Nutrição.',
  },
  {
    cnae: '8690-9/99',
    cnae_desc:
      'Outras  atividades  de  atenção  à  saúde  humana não especificadas anteriormente',
    item: '04.11',
    item_desc: 'Obstetrícia.',
  },
  {
    cnae: '8630-5/04',
    cnae_desc: 'Atividade odontológica',
    item: '04.12',
    item_desc: 'Odontologia.',
  },
  {
    cnae: '8650-0/99',
    cnae_desc:
      'Atividades  de  profissionais  da  área  de  saúde não especificadas anteriormente',
    item: '04.13',
    item_desc: 'Ortóptica.',
  },
  {
    cnae: '3250-7/03',
    cnae_desc:
      'Fabricação   de   aparelhos   e   utensílios   para correção    de    defeitos    físicos    e    aparelhos ortopédicos em geral sob encomenda',
    item: '04.14',
    item_desc: 'Próteses sob encomenda.',
  },
  {
    cnae: '3250-7/06',
    cnae_desc: 'Serviços de prótese dentária',
    item: '04.14',
    item_desc: 'Próteses sob encomenda.',
  },
  {
    cnae: '8650-0/03',
    cnae_desc: 'Atividades de psicologia e psicanálise',
    item: '04.15',
    item_desc: 'Psicanálise.',
  },
  {
    cnae: '8650-0/03',
    cnae_desc: 'Atividades de psicologia e psicanálise',
    item: '04.16',
    item_desc: 'Psicologia.',
  },
  {
    cnae: '8711-5/01',
    cnae_desc: 'Clínicas e residências geriátricas',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8711-5/02',
    cnae_desc: 'Instituições de longa permanência para idosos',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8711-5/03',
    cnae_desc:
      'Atividades  de  assistência  a  deficientes  físicos, imunodeprimidos e convalescentes',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8711-5/04',
    cnae_desc: 'Centros de apoio a pacientes com câncer e com AIDS',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8711-5/05',
    cnae_desc: 'Condomínios residenciais para idosos',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8720-4/01',
    cnae_desc:
      'Atividades      de      centros      de      assistência psicossocial',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8730-1/01',
    cnae_desc: 'Orfanatos',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8730-1/02',
    cnae_desc: 'Albergues assistencias',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8720-4/99',
    cnae_desc:
      'Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental       e       dependência       química       não especificadas anteriormente',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8730-1/99',
    cnae_desc:
      'Atividades  de  assistência  social  prestadas  em residências     coletivas     e     particulares     não especificadas anteriormente',
    item: '04.17',
    item_desc:
      'Casas de repouso e de recuperação, creches, asilos e congêneres.',
  },
  {
    cnae: '8630-5/07',
    cnae_desc: 'Atividades de reprodução humana assistida',
    item: '04.18',
    item_desc: 'Inseminação artificial, fertilização in vitro e congêneres.',
  },
  {
    cnae: '8630-5/07',
    cnae_desc: 'Atividades de reprodução humana assistida',
    item: '04.19',
    item_desc:
      'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.',
  },
  {
    cnae: '8640-2/12',
    cnae_desc: 'Serviços de hemoterapia',
    item: '04.19',
    item_desc:
      'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.',
  },
  {
    cnae: '8640-2/14',
    cnae_desc: 'Serviços   de   bancos   de   células   e   tecidos humanos',
    item: '04.19',
    item_desc:
      'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.',
  },
  {
    cnae: '8690-9/02',
    cnae_desc: 'Atividades de bancos de leite humano',
    item: '04.19',
    item_desc:
      'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.',
  },
  {
    cnae: '8690-9/99',
    cnae_desc:
      'Outras  atividades  de  atenção  à  saúde  humana não especificadas anteriormente',
    item: '04.19',
    item_desc:
      'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.',
  },
  {
    cnae: '8640-2/02',
    cnae_desc: 'Laboratórios clínicos',
    item: '04.20',
    item_desc:
      'Coleta de sangue, leite, tecidos, sêmen, órgãos e materiais biológicos de qualquer espécie.',
  },
  {
    cnae: '8621-6/01',
    cnae_desc: 'UTI móvel',
    item: '04.21',
    item_desc:
      'Unidade   de   atendimento,   assistência   ou   tratamento   móvel   e congêneres.',
  },
  {
    cnae: '8621-6/02',
    cnae_desc:
      'Serviços  móveis  de  atendimento  a  urgências, exceto por UTI móvel',
    item: '04.21',
    item_desc:
      'Unidade   de   atendimento,   assistência   ou   tratamento   móvel   e congêneres.',
  },
  {
    cnae: '8622-4/00',
    cnae_desc:
      'Serviços  de  remoção  de  pacientes,  exceto  os serviços móveis de atendimento a urgências',
    item: '04.21',
    item_desc:
      'Unidade   de   atendimento,   assistência   ou   tratamento   móvel   e congêneres.',
  },
  {
    cnae: '8712-3/00',
    cnae_desc:
      'Atividades de fornecimento de infra-estrutura de apoio e assistência a paciente no domicílio',
    item: '04.21',
    item_desc:
      'Unidade   de   atendimento,   assistência   ou   tratamento   móvel   e congêneres.',
  },
  {
    cnae: '6550-2/00',
    cnae_desc: 'Planos de saúde',
    item: '04.22',
    item_desc:
      'Planos   de   medicina   de   grupo   ou   individual   e   convênios   para prestação    de    assistência    médica,    hospitalar,    odontológica    e congêneres.',
  },
  {
    cnae: '6550-2/00',
    cnae_desc: 'Planos de saúde',
    item: '04.23',
    item_desc:
      'Outros  planos  de  saúde  que  se  cumpram  através  de  serviços  de terceiros  contratados,  credenciados,  cooperados  ou  apenas  pagos pelo operador do plano mediante indicação do usuário.',
  },
  {
    cnae: '7490-1/03',
    cnae_desc:
      'Serviços   de   agronomia   e   de   consultoria   às atividades agrícolas e pecuárias',
    item: '05.01',
    item_desc: 'Medicina veterinária e zootecnia.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.01',
    item_desc: 'Medicina veterinária e zootecnia.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.02',
    item_desc:
      'Hospitais,  clínicas,  ambulatórios,  prontos  socorros e  congêneres,  na área veterinária.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.03',
    item_desc: 'Laboratórios de análise na área veterinária.',
  },
  {
    cnae: '0162-8/01',
    cnae_desc: 'Serviço de inseminação artificial em animais',
    item: '05.04',
    item_desc: 'Inseminação artificial, fertilização in vitro e congêneres.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.05',
    item_desc: 'Bancos de sangue e de órgãos e congêneres.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.06',
    item_desc:
      'Coleta de sangue, leite, tecidos, sêmen, órgãos e materiais biológicos de qualquer espécie.',
  },
  {
    cnae: '7500-1/00',
    cnae_desc: 'Atividades veterinárias',
    item: '05.07',
    item_desc:
      'Unidade   de   atendimento,   assistência   ou   tratamento   móvel   e congêneres.',
  },
  {
    cnae: '8011-1/02',
    cnae_desc: 'Serviços de adestramento de cães de guarda',
    item: '05.08',
    item_desc:
      'Guarda,  tratamento,  amestramento,  embelezamento,  alojamento  e congêneres.',
  },
  {
    cnae: '9609-2/03',
    cnae_desc: 'Alojamento,    higiene    e    embelezamento    de animais',
    item: '05.08',
    item_desc:
      'Guarda,  tratamento,  amestramento,  embelezamento,  alojamento  e congêneres.',
  },
  {
    cnae: '0162-8/02',
    cnae_desc: 'SERVICO DE TOSQUIAMENTO DE OVINOS',
    item: '05.08',
    item_desc:
      'Guarda,  tratamento,  amestramento,  embelezamento,  alojamento  e congêneres.',
  },
  {
    cnae: '0162-8/03',
    cnae_desc: 'SERVICO DE MANEJO DE ANIMAIS',
    item: '05.08',
    item_desc:
      'Guarda,  tratamento,  amestramento,  embelezamento,  alojamento  e congêneres.',
  },
  {
    cnae: '0162-8/99',
    cnae_desc:
      'ATIVIDADES  DE  APOIO  A  PECUARIA  NAO ESPECIFICADAS ANTERIORMENTE',
    item: '05.08',
    item_desc:
      'Guarda,  tratamento,  amestramento,  embelezamento,  alojamento  e congêneres.',
  },
  {
    cnae: '6550-2/00',
    cnae_desc: 'Planos de saúde',
    item: '05.09',
    item_desc: 'Planos de atendimento e assistência médico veterinária.',
  },
  {
    cnae: '8690-9/04',
    cnae_desc: 'Atividades de podologia',
    item: '06.01',
    item_desc: 'Barbearia, cabeleireiros, manicuros, pedicuros e congêneres.',
  },
  {
    cnae: '9602-5/01',
    cnae_desc: 'Cabeleireiros',
    item: '06.01',
    item_desc: 'Barbearia, cabeleireiros, manicuros, pedicuros e congêneres.',
  },
  {
    cnae: '9602-5/02',
    cnae_desc: 'Outras atividades de tratamento de beleza',
    item: '06.01',
    item_desc: 'Barbearia, cabeleireiros, manicuros, pedicuros e congêneres.',
  },
  {
    cnae: '9602-5/02',
    cnae_desc:
      'Atividades   de   estética   e   outros   serviços   de cuidados com a beleza',
    item: '06.02',
    item_desc: 'Esteticistas, tratamento de pele, depilação e congêneres.',
  },
  {
    cnae: '9609-2/01',
    cnae_desc: 'Clínicas de estética e similares',
    item: '06.02',
    item_desc: 'Esteticistas, tratamento de pele, depilação e congêneres.',
  },
  {
    cnae: '9609-2/06',
    cnae_desc: 'Serviços de tatuagem e colocação de piercing',
    item: '06.02',
    item_desc: 'Esteticistas, tratamento de pele, depilação e congêneres.',
  },
  {
    cnae: '9609-2/99',
    cnae_desc:
      'Outras   atividades   de   serviços   pessoais   não especificadas anteriormente',
    item: '06.02',
    item_desc: 'Esteticistas, tratamento de pele, depilação e congêneres.',
  },
  {
    cnae: '9609-2/01',
    cnae_desc: 'Clínicas de estética e similares',
    item: '06.03',
    item_desc: 'Banhos, duchas, sauna, massagens e congêneres.',
  },
  {
    cnae: '9609-2/05',
    cnae_desc: 'Atividades de sauna e banhos',
    item: '06.03',
    item_desc: 'Banhos, duchas, sauna, massagens e congêneres.',
  },
  {
    cnae: '8592-9/01',
    cnae_desc: 'Ensino de dança',
    item: '06.04',
    item_desc:
      'Ginástica,   dança,   esportes,   natação,   artes   marciais   e   demais atividades físicas.',
  },
  {
    cnae: '8591-1/00',
    cnae_desc: 'Ensino de esportes',
    item: '06.04',
    item_desc:
      'Ginástica,   dança,   esportes,   natação,   artes   marciais   e   demais atividades físicas.',
  },
  {
    cnae: '9313-1/00',
    cnae_desc: 'Atividades de condicionamento físico',
    item: '06.04',
    item_desc:
      'Ginástica,   dança,   esportes,   natação,   artes   marciais   e   demais atividades físicas.',
  },
  {
    cnae: '9609-2/01',
    cnae_desc: 'Clínicas de estética e similares',
    item: '06.05',
    item_desc: 'Centros de emagrecimento, spa e congêneres.',
  },
  {
    cnae: '2391-5/01',
    cnae_desc:
      'BRITAMENTO      DE      PEDRAS,      EXCETO ASSOCIADO À EXTRAÇÃO',
    item: '07.01',
    item_desc:
      'Engenharia,     agronomia,     agrimensura,     arquitetura,     geologia, urbanismo, paisagismo e congêneres.',
  },
  {
    cnae: '7119-7/01',
    cnae_desc: 'Serviços de cartografia, topografia e geodésia',
    item: '07.01',
    item_desc:
      'Engenharia,     agronomia,     agrimensura,     arquitetura,     geologia, urbanismo, paisagismo e congêneres.',
  },
  {
    cnae: '7119-7/02',
    cnae_desc: 'Atividades de estudos geológicos',
    item: '07.01',
    item_desc:
      'Engenharia,     agronomia,     agrimensura,     arquitetura,     geologia, urbanismo, paisagismo e congêneres.',
  },
  {
    cnae: '7111-1/00',
    cnae_desc: 'Serviços de arquitetura',
    item: '07.01',
    item_desc:
      'Engenharia,     agronomia,     agrimensura,     arquitetura,     geologia, urbanismo, paisagismo e congêneres.',
  },
  {
    cnae: '7112-0/00',
    cnae_desc: 'Serviços de engenharia',
    item: '07.01',
    item_desc:
      'Engenharia,     agronomia,     agrimensura,     arquitetura,     geologia, urbanismo, paisagismo e congêneres.',
  },
  {
    cnae: '2330-3/05',
    cnae_desc:
      'PREPARAÇÃO  DE  MASSA  DE  CONCRETO  E AGAMASSA PARA CONSTRUÇÃO',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4211-1/01',
    cnae_desc: 'Construção de rodovias e ferrovias',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4211-1/02',
    cnae_desc: 'Pintura para sinalização em pistas rodoviárias e aeroportos',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4221-9/01',
    cnae_desc:
      'Construção   de   barragens   e   represas   para geração de energia elétrica',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4221-9/02',
    cnae_desc:
      'Construção de estações e redes de distribuição de energia elétrica',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4221-9/04',
    cnae_desc:
      'Construção     de     estações     e     redes     de telecomunicações',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4222-7/01',
    cnae_desc:
      'Construção de redes de abastecimento de água, coleta   de   esgoto   e   construções   correlatas, exceto obras de irrigação',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4222-7/02',
    cnae_desc: 'Obras de irrigação',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4292-8/01',
    cnae_desc: 'Montagem de estruturas metálicas',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4292-8/02',
    cnae_desc: 'Obras de montagem industrial',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4299-5/01',
    cnae_desc:
      'Construção     de     instalações     esportivas     e recreativas',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4311-8/02',
    cnae_desc: 'Preparação de canteiro e limpeza de terreno',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4322-3/01',
    cnae_desc: 'Instalações hidráulicas, sanitárias e de gás',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4322-3/02',
    cnae_desc:
      'Instalação  e  manutenção  de  sistemas  centrais de ar condicionado, de ventilação e refrigeração',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4322-3/03',
    cnae_desc: 'Instalações   de   sistema   de   prevenção   contra incêndio',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4329-1/03',
    cnae_desc:
      'Instalação,     manutenção     e     reparação     de elevadores, escadas e esteiras rolantes, exceto de fabricação própria',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/01',
    cnae_desc: 'Impermeabilização em obras de engenharia civil',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/03',
    cnae_desc: 'Obras de acabamento em gesso e estuque',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/04',
    cnae_desc: 'Serviços de pintura de edifícios em geral',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/05',
    cnae_desc:
      'Aplicação  de  revestimentos  e  de  resinas  em interiores e exteriores',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/01',
    cnae_desc: 'Administração de obras',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/03',
    cnae_desc: 'Obras de alvenaria',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/05',
    cnae_desc: 'Perfuração e construção de poços de água',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '3321-0/00',
    cnae_desc: 'Instalação    de    máquinas    e    equipamentos industriais',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4120-4/00',
    cnae_desc: 'Construção de edifícios',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4212-0/00',
    cnae_desc: 'Construção de obras-de-arte especiais',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4213-8/00',
    cnae_desc: 'Obras de urbanização - ruas, praças e calçadas',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4223-5/00',
    cnae_desc:
      'Construção  de  redes  de  transportes  por  dutos, exceto para água e esgoto',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4299-5/99',
    cnae_desc:
      'Outras     obras     de     engenharia     civil     não especificadas anteriormente',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4312-6/00',
    cnae_desc: 'Perfurações e sondagens',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4313-4/00',
    cnae_desc: 'Obras de terraplenagem',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4319-3/00',
    cnae_desc:
      'Serviços    de    preparação    do    terreno    não especificados anteriormente',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4321-5/00',
    cnae_desc: 'Instalação e manutenção elétrica',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4329-1/99',
    cnae_desc:
      'Outras  obras  de  instalações  em  construções não especificadas anteriormente',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/99',
    cnae_desc: 'Outras obras de acabamento da construção',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4391-6/00',
    cnae_desc: 'Obras de fundações',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/99',
    cnae_desc:
      'Serviços   especializados   para   construção   não especificados anteriormente',
    item: '07.02',
    item_desc:
      'Execução, por administração, empreitada ou subempreitada, de obras de   construção   civil,   hidráulica   ou   elétrica   e   de   outras   obras semelhantes,  inclusive  sondagem,  perfuração  de  poços,  escavação, drenagem  e irrigação,  terraplenagem,  pavimentação,  concretagem  e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '7112-0/00',
    cnae_desc: 'Serviços de engenharia',
    item: '07.03',
    item_desc:
      'Elaboração   de   planos   diretores,   estudos   de   viabilidade,   estudos organizacionais  e  outros,  relacionados  com  obras  e  serviços  de engenharia;  elaboração  de  anteprojetos,  projetos  básicos  e  projetos executivos para trabalhos de engenharia.',
  },
  {
    cnae: '4311-8/01',
    cnae_desc: 'Demolição de edifícios e outras estruturas',
    item: '07.04',
    item_desc: 'Demolição.',
  },
  {
    cnae: '4211-1/01',
    cnae_desc: 'Construção de rodovias e ferrovias',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4211-1/02',
    cnae_desc: 'Pintura para sinalização em pistas rodoviárias e aeroportos',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4221-9/03',
    cnae_desc: 'Manutenção de redes de distribuição de energia elétrica',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4221-9/05',
    cnae_desc:
      'Manutenção     de     estações     e     redes     de telecomunicações',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4322-3/01',
    cnae_desc: 'Instalações hidráulicas, sanitárias e de gás',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/03',
    cnae_desc: 'Obras de acabamento em gesso e estuque',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/04',
    cnae_desc: 'Serviços de pintura de edifícios em geral',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/05',
    cnae_desc:
      'Aplicação  de  revestimentos  e  de  resinas  em interiores e exteriores',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/03',
    cnae_desc: 'Obras de alvenaria',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '9102-3/02',
    cnae_desc:
      'Restauração    e    conservação    de    lugares   e prédios históricos',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4120-4/00',
    cnae_desc: 'Construção de edifícios',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4212-0/00',
    cnae_desc: 'Construção de obras-de-arte especiais',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4321-5/00',
    cnae_desc: 'Instalação e manutenção elétrica',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/99',
    cnae_desc: 'Outras obras de acabamento da construção',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4399-1/99',
    cnae_desc:
      'Serviços   especializados   para   construção   não especificados anteriormente',
    item: '07.05',
    item_desc:
      'Reparação,  conservação  e  reforma  de  edifícios,  estradas,  pontes, portos   e   congêneres   (exceto   o   fornecimento   de   mercadorias produzidas  pelo  prestador  dos  serviços,  fora  do  local  da  prestação dos serviços, que fica sujeito ao ICMS).',
  },
  {
    cnae: '4330-4/02',
    cnae_desc:
      'Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material',
    item: '07.06',
    item_desc:
      'Colocação  e  instalação  de  tapetes,  carpetes,  assoalhos,  cortinas, revestimentos   de   parede,   vidros,   divisórias,   placas   de   gesso   e congêneres, com material fornecido pelo tomador do serviço.',
  },
  {
    cnae: '4330-4/03',
    cnae_desc: 'Obras de acabamento em gesso e estuque',
    item: '07.06',
    item_desc:
      'Colocação  e  instalação  de  tapetes,  carpetes,  assoalhos,  cortinas, revestimentos   de   parede,   vidros,   divisórias,   placas   de   gesso   e congêneres, com material fornecido pelo tomador do serviço.',
  },
  {
    cnae: '4330-4/05',
    cnae_desc:
      'Aplicação  de  revestimentos  e  de  resinas  em interiores e exteriores',
    item: '07.06',
    item_desc:
      'Colocação  e  instalação  de  tapetes,  carpetes,  assoalhos,  cortinas, revestimentos   de   parede,   vidros,   divisórias,   placas   de   gesso   e congêneres, com material fornecido pelo tomador do serviço.',
  },
  {
    cnae: '4330-4/99',
    cnae_desc: 'Outras obras de acabamento da construção',
    item: '07.06',
    item_desc:
      'Colocação  e  instalação  de  tapetes,  carpetes,  assoalhos,  cortinas, revestimentos   de   parede,   vidros,   divisórias,   placas   de   gesso   e congêneres, com material fornecido pelo tomador do serviço.',
  },
  {
    cnae: '4330-4/05',
    cnae_desc:
      'Aplicação  de  revestimentos  e  de  resinas  em interiores e exteriores',
    item: '07.07',
    item_desc:
      'Recuperação,    raspagem,    polimento    e    lustração    de    pisos    e congêneres.',
  },
  {
    cnae: '4329-1/05',
    cnae_desc: 'Tratamentos térmicos, acústicos ou de vibração',
    item: '07.08',
    item_desc: 'Calafetação.',
  },
  {
    cnae: '4330-4/05',
    cnae_desc:
      'Aplicação  de  revestimentos  e  de  resinas  em interiores e exteriores',
    item: '07.08',
    item_desc: 'Calafetação.',
  },
  {
    cnae: '3831-9/01',
    cnae_desc: 'Recuperação de sucatas de alumínio',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3839-4/01',
    cnae_desc: 'Usinas de compostagem',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3811-4/00',
    cnae_desc: 'Coleta de resíduos não-perigosos',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3812-2/00',
    cnae_desc: 'Coleta de resíduos perigosos',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3821-1/00',
    cnae_desc: 'Tratamento   e   disposição   de   resíduos   não- perigosos',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3822-0/00',
    cnae_desc: 'Tratamento e disposição de resíduos perigosos',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3831-9/99',
    cnae_desc: 'Recuperação   de   materiais   metálicos,   exceto alumínio',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3832-7/00',
    cnae_desc: 'Recuperação de materiais plásticos',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3839-4/99',
    cnae_desc:
      'Recuperação   de   materiais   não   especificados anteriormente',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '8129-0/00',
    cnae_desc:
      'Atividades    de    limpeza    não    especificadas anteriormente',
    item: '07.09',
    item_desc:
      'Varrição,   coleta,   remoção,   incineração,   tratamento,   reciclagem, separação  e  destinação  final  de  lixo,  rejeitos  e  outros  resíduos quaisquer.',
  },
  {
    cnae: '3702-9/00',
    cnae_desc:
      'Atividades   relacionadas   a   esgoto,   exceto   a gestão de redes',
    item: '07.10',
    item_desc:
      'Limpeza, manutenção e conservação de vias e logradouros públicos, imóveis, chaminés, piscinas, parques, jardins e congêneres.',
  },
  {
    cnae: '8121-4/00',
    cnae_desc: 'Limpeza em prédios e em domicílios',
    item: '07.10',
    item_desc:
      'Limpeza, manutenção e conservação de vias e logradouros públicos, imóveis, chaminés, piscinas, parques, jardins e congêneres.',
  },
  {
    cnae: '8129-0/00',
    cnae_desc:
      'Atividades    de    limpeza    não    especificadas anteriormente',
    item: '07.10',
    item_desc:
      'Limpeza, manutenção e conservação de vias e logradouros públicos, imóveis, chaminés, piscinas, parques, jardins e congêneres.',
  },
  {
    cnae: '7410-2/02',
    cnae_desc: 'Decoração de interiores',
    item: '07.11',
    item_desc: 'Decoração e jardinagem, inclusive corte e poda de árvores.',
  },
  {
    cnae: '8130-3/00',
    cnae_desc: 'Atividades paisagísticas',
    item: '07.11',
    item_desc: 'Decoração e jardinagem, inclusive corte e poda de árvores.',
  },
  {
    cnae: '3821-1/00',
    cnae_desc: 'Tratamento   e   disposição   de   resíduos   não- perigosos',
    item: '07.12',
    item_desc:
      'Controle e tratamento de efluentes de qualquer natureza e de agentes físicos, químicos e biolõgicos',
  },
  {
    cnae: '3822-0/00',
    cnae_desc: 'Tratamento e disposição de resíduos perigosos',
    item: '07.12',
    item_desc:
      'Controle e tratamento de efluentes de qualquer natureza e de agentes físicos, químicos e biolõgicos',
  },
  {
    cnae: '0161-0/01',
    cnae_desc: 'Serviço  de  pulverização  e  controle  de  pragas agrícolas',
    item: '07.13',
    item_desc:
      'Dedetização,  desinfecção,  desinsetização,  imunização,  higienização, desratização, pulverização e congêneres.',
  },
  {
    cnae: '0162-8/99',
    cnae_desc:
      'ATIVIDADES  DE  APOIO  A  PECUARIA  NAO ESPECIFICADAS ANTERIORMENTE',
    item: '07.13',
    item_desc:
      'Dedetização,  desinfecção,  desinsetização,  imunização,  higienização, desratização, pulverização e congêneres.',
  },
  {
    cnae: '8122-2/00',
    cnae_desc: 'Imunização e controle de pragas urbanas',
    item: '07.13',
    item_desc:
      'Dedetização,  desinfecção,  desinsetização,  imunização,  higienização, desratização, pulverização e congêneres.',
  },
  {
    cnae: '0161-0/03',
    cnae_desc: 'SERVICO  DE  PREPARACAO  DE  TERRENO, CULTIVO E COLHEITA',
    item: '07.16',
    item_desc:
      'Florestamento, reflorestamento, semeadura, adubação e congêneres.',
  },
  {
    cnae: '0220-9/06',
    cnae_desc: 'Conservação de florestas nativas',
    item: '07.16',
    item_desc:
      'Florestamento, reflorestamento, semeadura, adubação e congêneres.',
  },
  {
    cnae: '0230-6/00',
    cnae_desc: 'Atividades de apoio à produção florestal',
    item: '07.16',
    item_desc:
      'Florestamento, reflorestamento, semeadura, adubação e congêneres.',
  },
  {
    cnae: '4299-5/99',
    cnae_desc:
      'Outras     obras     de     engenharia     civil     não especificadas anteriormente',
    item: '07.17',
    item_desc: 'Escoramento, contenção de encostas e serviços congêneres.',
  },
  {
    cnae: '3900-5/00',
    cnae_desc: 'Descontaminação  e  outros  serviços  de  gestão de resíduos',
    item: '07.18',
    item_desc:
      'Limpeza  e  dragagem  de  rios,  portos,  canais,  baías,  lagos,  lagoas, represas, açudes e congêneres.',
  },
  {
    cnae: '4291-0/00',
    cnae_desc: 'Obras portuárias, marítimas e fluviais',
    item: '07.18',
    item_desc:
      'Limpeza  e  dragagem  de  rios,  portos,  canais,  baías,  lagos,  lagoas, represas, açudes e congêneres.',
  },
  {
    cnae: '7112-0/00',
    cnae_desc: 'Serviços de engenharia',
    item: '07.19',
    item_desc:
      'Acompanhamento    e    fiscalização    da    execução    de    obras    de engenharia, arquitetura e urbanismo.',
  },
  {
    cnae: '7119-7/01',
    cnae_desc: 'Serviços de cartografia, topografia e geodésia',
    item: '07.20',
    item_desc:
      'Aerofotogrametria (inclusive interpretação), cartografia, mapeamento, levantamentos  topográficos,  batimétricos,  geográficos,  geodésicos, geológicos, geofísicos e congêneres.',
  },
  {
    cnae: '7119-7/02',
    cnae_desc: 'Atividades de estudos geológicos',
    item: '07.20',
    item_desc:
      'Aerofotogrametria (inclusive interpretação), cartografia, mapeamento, levantamentos  topográficos,  batimétricos,  geográficos,  geodésicos, geológicos, geofísicos e congêneres.',
  },
  {
    cnae: '7119-7/99',
    cnae_desc:
      'Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente',
    item: '07.20',
    item_desc:
      'Aerofotogrametria (inclusive interpretação), cartografia, mapeamento, levantamentos  topográficos,  batimétricos,  geográficos,  geodésicos, geológicos, geofísicos e congêneres.',
  },
  {
    cnae: '7490-1/02',
    cnae_desc: 'Escafandria e mergulho',
    item: '07.21',
    item_desc:
      'Pesquisa,      perfuração,      cimentação,      mergulho,      perfilagem, concretação,    testemunhagem,    pescaria,    estimulação    e    outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.',
  },
  {
    cnae: '0910-6/00',
    cnae_desc: 'Atividades de apoio à extração de petróleo e gás natural',
    item: '07.21',
    item_desc:
      'Pesquisa,      perfuração,      cimentação,      mergulho,      perfilagem, concretação,    testemunhagem,    pescaria,    estimulação    e    outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.',
  },
  {
    cnae: '0990-4/01',
    cnae_desc: 'ATIVIDADES   DE   APOIO   A   EXTRACAO   DE MINERIO DE FERRO',
    item: '07.21',
    item_desc:
      'Pesquisa,      perfuração,      cimentação,      mergulho,      perfilagem, concretação,    testemunhagem,    pescaria,    estimulação    e    outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.',
  },
  {
    cnae: '0990-4/02',
    cnae_desc:
      'ATIVIDADES   DE   APOIO   A   EXTRACAO   DE MINERAIS METÁLICOS NÃO-FERROSOS',
    item: '07.21',
    item_desc:
      'Pesquisa,      perfuração,      cimentação,      mergulho,      perfilagem, concretação,    testemunhagem,    pescaria,    estimulação    e    outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.',
  },
  {
    cnae: '0990-4/03',
    cnae_desc:
      'ATIVIDADES   DE   APOIO   À   EXTRAÇÃO   DE MINERAIS NÃO-METÁLICOS',
    item: '07.21',
    item_desc:
      'Pesquisa,      perfuração,      cimentação,      mergulho,      perfilagem, concretação,    testemunhagem,    pescaria,    estimulação    e    outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '07.22',
    item_desc: 'Nucleação e bombardeamento de nuvens e congêneres.',
  },
  {
    cnae: '8511-2/00',
    cnae_desc: 'Educação infantil – creche',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8512-1/00',
    cnae_desc: 'Educação infantil - pré-escola',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8513-9/00',
    cnae_desc: 'Ensino fundamental',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8520-1/00',
    cnae_desc: 'Ensino médio',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8531-7/00',
    cnae_desc: 'Educação superior – graduação',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8532-5/00',
    cnae_desc: 'Educação    superior    -    graduação    e    pós- graduação',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8533-3/00',
    cnae_desc: 'Educação superior -  pós-graduação e extensão',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8541-4/00',
    cnae_desc: 'Educação profissional de nível técnico',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8542-2/00',
    cnae_desc: 'Educação profissional de nível tecnológico',
    item: '08.01',
    item_desc: 'Ensino regular pré escolar, fundamental, médio e superior.',
  },
  {
    cnae: '8550-3/02',
    cnae_desc: 'Atividades  de  apoio  à  educação,  exceto  caixas escolares',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8592-9/02',
    cnae_desc: 'Ensino de artes cênicas, exceto dança',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8592-9/03',
    cnae_desc: 'Ensino de música',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/01',
    cnae_desc: 'Formação de condutores',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/02',
    cnae_desc: 'Cursos de pilotagem',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/03',
    cnae_desc: 'Treinamento em informática',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/04',
    cnae_desc: 'Treinamento em desenvolvimento profissional e gerencial',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/05',
    cnae_desc: 'Cursos preparatórios para concursos',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8592-9/99',
    cnae_desc:
      'Ensino   de   arte   e   cultura   não   especificado anteriormente',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8593-7/00',
    cnae_desc: 'Ensino de idiomas',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '8599-6/99',
    cnae_desc:
      'Outras  atividades  de  ensino  não  especificadas anteriormente',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '9312-3/00',
    cnae_desc: 'CLUBES       SOCIAIS,        ESPORTIVOS       E SIMILARES',
    item: '08.02',
    item_desc:
      'Instrução,    treinamento,    orientação    pedagógica    e    educacional, avaliação de conhecimentos de qualquer natureza.',
  },
  {
    cnae: '5510-8/01',
    cnae_desc: 'Hotéis',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5510-8/02',
    cnae_desc: 'Apart-hotéis',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5510-8/03',
    cnae_desc: 'Motéis',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5590-6/01',
    cnae_desc: 'Albergues, exceto assistenciais',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5590-6/02',
    cnae_desc: 'Campings',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5590-6/03',
    cnae_desc: 'Pensões (alojamento)',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '5590-6/99',
    cnae_desc:
      'Outros       alojamentos       não       especificados anteriormente',
    item: '09.01',
    item_desc:
      'Hospedagem    de    qualquer    natureza    em    hotéis,    apart-service condominiais,  flat,  apart-hotéis,  hotéis  residência,  residence-service, suite  service,  hotelaria  marítima,  motéis,  pensões  e  congêneres; ocupação  por  temporada  com  fornecimento  de  serviço  (o  valor  da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).',
  },
  {
    cnae: '4929-9/03',
    cnae_desc:
      'Organização     de     excursões     em     veículos rodoviários próprios, municipal',
    item: '09.02',
    item_desc:
      'Agenciamento, organização, promoção, intermediação e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.',
  },
  {
    cnae: '4929-9/04',
    cnae_desc:
      'Organização     de     excursões     em     veículos rodoviários            próprios,            intermunicipal, interestadual e internacional',
    item: '09.02',
    item_desc:
      'Agenciamento, organização, promoção, intermediação e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.',
  },
  {
    cnae: '7911-2/00',
    cnae_desc: 'Agências de viagens',
    item: '09.02',
    item_desc:
      'Agenciamento, organização, promoção, intermediação e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.',
  },
  {
    cnae: '7912-1/00',
    cnae_desc: 'Operadores turísticos',
    item: '09.02',
    item_desc:
      'Agenciamento, organização, promoção, intermediação e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.',
  },
  {
    cnae: '7990-2/00',
    cnae_desc:
      'Serviços   de   reservas   e   outros   serviços   de turismo não especificados anteriormente',
    item: '09.02',
    item_desc:
      'Agenciamento, organização, promoção, intermediação e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.',
  },
  {
    cnae: '7912-1/00',
    cnae_desc: 'Operadores turísticos',
    item: '09.03',
    item_desc: 'Guias de turismo.',
  },
  {
    cnae: '6612-6/03',
    cnae_desc: 'Corretoras de câmbio',
    item: '10.01',
    item_desc:
      'Agenciamento, corretagem ou intermediação de câmbio, de seguros, de cartões de crédito, de planos de saúde e de planos de previdência privada.',
  },
  {
    cnae: '6622-3/00',
    cnae_desc:
      'Corretores e agentes de seguros, de planos de previdência complementar e de saúde',
    item: '10.01',
    item_desc:
      'Agenciamento, corretagem ou intermediação de câmbio, de seguros, de cartões de crédito, de planos de saúde e de planos de previdência privada.',
  },
  {
    cnae: '6612-6/01',
    cnae_desc: 'Corretoras de títulos e valores mobiliários',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6612-6/02',
    cnae_desc: 'Distribuidoras de títulos e valores mobiliários',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6612-6/04',
    cnae_desc: 'Corretoras de contratos de mercadorias',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6612-6/05',
    cnae_desc: 'Agentes    de    investimentos    em    aplicações financeiras',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '7490-1/04',
    cnae_desc:
      'Atividades de intermediação e agenciamento de serviços     e     negócios     em     geral,     exceto imobiliários',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '7490-1/05',
    cnae_desc:
      'Agenciamento  de  profissionais  para  atividades esportivas, culturais e artísticas',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '8299-7/05',
    cnae_desc: 'Serviços    de    levantamento    de    fundos    sob contrato',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '9609-2/02',
    cnae_desc: 'Agências matrimoniais',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6619-3/99',
    cnae_desc:
      'Outras    atividades    auxiliares    dos    serviços financeiros não especificadas anteriormente',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6622-3/00',
    cnae_desc:
      'Corretores e agentes de seguros, de planos de previdência complementar e de saúde',
    item: '10.02',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  títulos  em  geral, valores mobiliários e contratos quaisquer.',
  },
  {
    cnae: '6022-5/02',
    cnae_desc:
      'Atividades     relacionadas     à     televisão     por assinatura, exceto programadoras',
    item: '10.03',
    item_desc:
      'Agenciamento,    corretagem    ou    intermediação    de    direitos    da propriedade industrial, artística ou literária.',
  },
  {
    cnae: '6911-7/03',
    cnae_desc: 'Agente de propriedade industrial',
    item: '10.03',
    item_desc:
      'Agenciamento,    corretagem    ou    intermediação    de    direitos    da propriedade industrial, artística ou literária.',
  },
  {
    cnae: '7490-1/05',
    cnae_desc:
      'Agenciamento  de  profissionais  para  atividades esportivas, culturais e artísticas',
    item: '10.03',
    item_desc:
      'Agenciamento,    corretagem    ou    intermediação    de    direitos    da propriedade industrial, artística ou literária.',
  },
  {
    cnae: '5811-5/00',
    cnae_desc: 'Edição de livros',
    item: '10.03',
    item_desc:
      'Agenciamento,    corretagem    ou    intermediação    de    direitos    da propriedade industrial, artística ou literária.',
  },
  {
    cnae: '7490-1/04',
    cnae_desc:
      'Atividades de intermediação e agenciamento de serviços     e     negócios     em     geral,     exceto imobiliários',
    item: '10.04',
    item_desc:
      'Agenciamento,    corretagem    ou   intermediação   de   contratos   de arrendamento   mercantil   (leasing),   de   franquia   (franchising)   e   de faturização (factoring).',
  },
  {
    cnae: '4512-9/02',
    cnae_desc:
      'Comércio     sob     consignação     de     veículos automotores',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '5250-8/03',
    cnae_desc:
      'Agenciamento    de    cargas,    exceto    para    o transporte marítimo',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '6612-6/05',
    cnae_desc: 'Agentes    de    investimentos    em    aplicações financeiras',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '6821-8/01',
    cnae_desc: 'Corretagem na compra e venda e avaliação de imóveis',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '6821-8/02',
    cnae_desc: 'Corretagem no aluguel de imóveis',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '8299-7/02',
    cnae_desc: 'Emissão  de  vales-alimentação,  vales-transporte e similares',
    item: '10.05',
    item_desc:
      'Agenciamento,  corretagem  ou  intermediação  de  bens  móveis  ou imóveis,   não   abrangidos   em   outros   itens   ou   subitens,   inclusive aqueles  realizados  no  âmbito  de  Bolsas  de  Mercadorias  e  Futuros, por quaisquer meios.',
  },
  {
    cnae: '5232-0/00',
    cnae_desc: 'Atividades de agenciamento marítimo',
    item: '10.06',
    item_desc: 'Agenciamento marítimo.',
  },
  {
    cnae: '6391-7/00',
    cnae_desc: 'Agências de notícias',
    item: '10.07',
    item_desc: 'Agenciamento de notícias.',
  },
  {
    cnae: '7490-1/04',
    cnae_desc:
      'Atividades de intermediação e agenciamento de serviços     e     negócios     em     geral,     exceto imobiliários',
    item: '10.08',
    item_desc:
      'Agenciamento     de     publicidade     e     propaganda,     inclusive     o agenciamento de veiculação por quaisquer meios.',
  },
  {
    cnae: '7312-2/00',
    cnae_desc:
      'Agenciamento   de   espaços   para   publicidade, exceto em veículos de comunicação',
    item: '10.08',
    item_desc:
      'Agenciamento     de     publicidade     e     propaganda,     inclusive     o agenciamento de veiculação por quaisquer meios.',
  },
  {
    cnae: '4512-9/01',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio de veículos automotores',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4512-9/02',
    cnae_desc:
      'Comércio     sob     consignação     de     veículos automotores',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4530-7/06',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio de peças e acessórios novos e usados para veículos automotores',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4542-1/01',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio de motocicletas e motonetas, peças e acessórios',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4618-4/01',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio    de    medicamentos,    cosméticos    e produtos de perfumaria',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4618-4/02',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio  de  instrumentos  e  materiais  odonto- médico-hospitalares',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4618-4/03',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio     de     jornais,     revistas     e     outras publicações',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '6619-3/03',
    cnae_desc: 'REPRESENTACOES            DE            BANCOS ESTRANGEIROS',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4611-7/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio    de    matérias-primas    agrícolas    e animais vivos',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4612-5/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio  de  combustíveis,  minerais,  produtos siderúrgicos e químicos',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4613-3/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio  de  madeira,  material  de  construção e ferragens',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4614-1/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio      de      máquinas,      equipamentos, embarcações e aeronaves',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4615-0/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio de eletrodomésticos, móveis e artigos de uso doméstico',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4616-8/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio   de   têxteis,   vestuário,   calçados   e artigos de viagem',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4617-6/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio  de  produtos  alimentícios,  bebidas  e fumo',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4618-4/99',
    cnae_desc:
      'Outros representantes comerciais e agentes do comércio    especializado    em    produtos    não especificados anteriormente',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '4619-2/00',
    cnae_desc:
      'Representantes    comerciais    e    agentes    do comércio    de    mercadorias    em    geral    não especializado',
    item: '10.09',
    item_desc: 'Representação de qualquer natureza, inclusive comercial.',
  },
  {
    cnae: '5913-8/00',
    cnae_desc:
      'Distribuição   cinematográfica,   de   vídeo   e   de programas de televisão',
    item: '10.10',
    item_desc: 'Distribuição de bens de terceiros',
  },
  {
    cnae: '5223-1/00',
    cnae_desc: 'Estacionamento de veículos',
    item: '11.01',
    item_desc:
      'Guarda  e  estacionamento  de  veículos  terrestres  automotores,  de aeronaves e de embarcações.',
  },
  {
    cnae: '5240-1/99',
    cnae_desc:
      'Atividades   auxiliares   dos   transportes   aéreos, exceto  operação  dos  aeroportos  e  campos  de aterrissagem',
    item: '11.01',
    item_desc:
      'Guarda  e  estacionamento  de  veículos  terrestres  automotores,  de aeronaves e de embarcações.',
  },
  {
    cnae: '9329-8/99',
    cnae_desc:
      'Outras   atividades   de   recreação   e   lazer   não especificadas anteriormente',
    item: '11.01',
    item_desc:
      'Guarda  e  estacionamento  de  veículos  terrestres  automotores,  de aeronaves e de embarcações.',
  },
  {
    cnae: '8011-1/01',
    cnae_desc: 'Atividades de vigilância e segurança privada',
    item: '11.02',
    item_desc: 'Vigilância, segurança ou monitoramento de bens e pessoas.',
  },
  {
    cnae: '8020-0/00',
    cnae_desc: 'Atividades  de  monitoramento  de  sistemas  de segurança',
    item: '11.02',
    item_desc: 'Vigilância, segurança ou monitoramento de bens e pessoas.',
  },
  {
    cnae: '5229-0/99',
    cnae_desc:
      'Outras   atividades   auxiliares   dos   transportes terrestres não especificadas anteriormente',
    item: '11.03',
    item_desc: 'Escolta, inclusive de veículos e cargas.',
  },
  {
    cnae: '4930-2/04',
    cnae_desc: 'Transporte rodoviário de mudanças',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5211-7/01',
    cnae_desc: 'Armazéns gerais - emissão de warrant',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5211-7/02',
    cnae_desc: 'Guarda-móveis',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5231-1/02',
    cnae_desc: 'Operações de terminais',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5250-8/04',
    cnae_desc: 'Organização logística do transporte de carga',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5211-7/99',
    cnae_desc:
      'Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '5212-5/00',
    cnae_desc: 'Carga e descarga',
    item: '11.04',
    item_desc:
      'Armazenamento, depósito, carga, descarga, arrumação e guarda de bens de qualquer espécie',
  },
  {
    cnae: '9001-9/01',
    cnae_desc: 'Produção teatral',
    item: '12.01',
    item_desc: 'Espetáculos teatrais.',
  },
  {
    cnae: '9001-9/99',
    cnae_desc:
      'Artes     cênicas,     espetáculos     e     atividades complementares            não            especificados anteriormente',
    item: '12.01',
    item_desc: 'Espetáculos teatrais.',
  },
  {
    cnae: '5914-6/00',
    cnae_desc: 'Atividades de exibição cinematográfica',
    item: '12.02',
    item_desc: 'Exibições cinematográficas.',
  },
  {
    cnae: '9001-9/04',
    cnae_desc:
      'Produção     de     espetáculos     circenses,     de marionetes e similares',
    item: '12.03',
    item_desc: 'Espetáculos circenses.',
  },
  {
    cnae: '9001-9/99',
    cnae_desc:
      'Artes     cênicas,     espetáculos     e     atividades complementares            não            especificados anteriormente',
    item: '12.03',
    item_desc: 'Espetáculos circenses.',
  },
  {
    cnae: '9001-9/99',
    cnae_desc:
      'Artes     cênicas,     espetáculos     e     atividades complementares            não            especificados anteriormente',
    item: '12.04',
    item_desc: 'Programas de auditório.',
  },
  {
    cnae: '9001-9/99',
    cnae_desc:
      'Artes     cênicas,     espetáculos     e     atividades complementares            não            especificados anteriormente',
    item: '12.05',
    item_desc: 'Parques de diversões, centros de lazer e congêneres.',
  },
  {
    cnae: '9103-1/00',
    cnae_desc:
      'Atividades   de   jardins   botânicos,   zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental',
    item: '12.05',
    item_desc: 'Parques de diversões, centros de lazer e congêneres.',
  },
  {
    cnae: '9321-2/00',
    cnae_desc: 'Parques de diversão e parques temáticos',
    item: '12.05',
    item_desc: 'Parques de diversões, centros de lazer e congêneres.',
  },
  {
    cnae: '9329-8/01',
    cnae_desc: 'Discotecas,   danceterias,   salões   de   dança   e similares',
    item: '12.06',
    item_desc: 'Boates, taxi dancing e congêneres',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.07',
    item_desc:
      'Shows,  ballet,  danças,  desfiles,  bailes,  óperas,  concertos,  recitais, festivais e congêneres.',
  },
  {
    cnae: '9001-9/03',
    cnae_desc: 'Produção de espetáculos de dança',
    item: '12.07',
    item_desc:
      'Shows,  ballet,  danças,  desfiles,  bailes,  óperas,  concertos,  recitais, festivais e congêneres.',
  },
  {
    cnae: '8230-0/01',
    cnae_desc:
      'Serviços de  organização de  feiras,  congressos, exposições e festas',
    item: '12.08',
    item_desc: 'Feiras, exposições, congressos e congêneres.',
  },
  {
    cnae: '9329-8/02',
    cnae_desc: 'Exploração de boliches',
    item: '12.09',
    item_desc: 'Bilhares, boliches e diversões eletrônicas ou não.',
  },
  {
    cnae: '9329-8/03',
    cnae_desc: 'Exploração de jogos de sinuca, bilhar e similares',
    item: '12.09',
    item_desc: 'Bilhares, boliches e diversões eletrônicas ou não.',
  },
  {
    cnae: '9329-8/04',
    cnae_desc: 'Exploração de jogos eletrônicos recreativos',
    item: '12.09',
    item_desc: 'Bilhares, boliches e diversões eletrônicas ou não.',
  },
  {
    cnae: '9200-3/99',
    cnae_desc:
      'Exploração  de  jogos  de  azar  e  apostas  não especificados anteriormente',
    item: '12.09',
    item_desc: 'Bilhares, boliches e diversões eletrônicas ou não.',
  },
  {
    cnae: '9001-9/05',
    cnae_desc:
      'Produção      de      espetáculos      de      rodeios, vaquejadas e similares',
    item: '12.10',
    item_desc: 'Corridas e competições de animais.',
  },
  {
    cnae: '9200-3/02',
    cnae_desc: 'Exploração de apostas em corridas de cavalos',
    item: '12.10',
    item_desc: 'Corridas e competições de animais.',
  },
  {
    cnae: '9319-1/01',
    cnae_desc: 'Produção e promoção de eventos esportivos',
    item: '12.11',
    item_desc:
      'Competições esportivas ou de destreza física ou intelectual,  com ou sem a participação do espectador.',
  },
  {
    cnae: '9319-1/99',
    cnae_desc:
      'Outras  atividades  esportivas  não  especificadas anteriormente',
    item: '12.11',
    item_desc:
      'Competições esportivas ou de destreza física ou intelectual,  com ou sem a participação do espectador.',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.12',
    item_desc: 'Execução de música',
  },
  {
    cnae: '9001-9/01',
    cnae_desc: 'Produção teatral',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '9001-9/03',
    cnae_desc: 'Produção de espetáculos de dança',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '9001-9/04',
    cnae_desc:
      'Produção     de     espetáculos     circenses,     de marionetes e similares',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '5911-1/99',
    cnae_desc:
      'Atividades   de   produção   cinematográfica,   de vídeos   e   de   programas   de   televisão   não especificadas anteriormente',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '6021-7/00',
    cnae_desc: 'Atividades de televisão aberta',
    item: '12.13',
    item_desc:
      'Produção,   mediante   ou   sem   encomenda   prévia,   de   eventos, espetáculos,   entrevistas,   shows,   ballet,   danças,   desfiles,   bailes, teatros, óperas, concertos, recitais, festivais e congêneres.',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.14',
    item_desc:
      'Fornecimento de música para ambientes fechados ou não, mediante transmissão por qualquer processo.',
  },
  {
    cnae: '9001-9/06',
    cnae_desc: 'Atividades de sonorização e de iluminação',
    item: '12.14',
    item_desc:
      'Fornecimento de música para ambientes fechados ou não, mediante transmissão por qualquer processo.',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.15',
    item_desc:
      'Desfiles  de  blocos  carnavalescos  ou  folclóricos,  trios  elétricos  e congêneres.',
  },
  {
    cnae: '9001-9/03',
    cnae_desc: 'Produção de espetáculos de dança',
    item: '12.15',
    item_desc:
      'Desfiles  de  blocos  carnavalescos  ou  folclóricos,  trios  elétricos  e congêneres.',
  },
  {
    cnae: '9493-6/00',
    cnae_desc:
      'Atividades de organizações associativas ligadas à cultura e à arte',
    item: '12.15',
    item_desc:
      'Desfiles  de  blocos  carnavalescos  ou  folclóricos,  trios  elétricos  e congêneres.',
  },
  {
    cnae: '9001-9/02',
    cnae_desc: 'Produção musical',
    item: '12.16',
    item_desc:
      'Exibição   de   filmes,   entrevistas,   musicais,   espetáculos,   shows, concertos,   desfiles,   óperas,   competições   esportivas,   de   destreza intelectual ou congêneres.',
  },
  {
    cnae: '5914-6/00',
    cnae_desc: 'Atividades de exibição cinematográfica',
    item: '12.16',
    item_desc:
      'Exibição   de   filmes,   entrevistas,   musicais,   espetáculos,   shows, concertos,   desfiles,   óperas,   competições   esportivas,   de   destreza intelectual ou congêneres.',
  },
  {
    cnae: '9329-8/99',
    cnae_desc:
      'Outras   atividades   de   recreação   e   lazer   não especificadas anteriormente',
    item: '12.17',
    item_desc:
      'Recreação  e  animação,  inclusive  em  festas  e  eventos  de  qualquer natureza.',
  },
  {
    cnae: '1830-0/01',
    cnae_desc: 'REPRODUCAO   DE   SOM   EM   QUALQUER SUPORTE',
    item: '13.02',
    item_desc:
      'Fonografia  ou  gravação  de  sons,  inclusive  trucagem,  dublagem, mixagem e congêneres.',
  },
  {
    cnae: '5912-0/01',
    cnae_desc: 'Serviços de dublagem',
    item: '13.02',
    item_desc:
      'Fonografia  ou  gravação  de  sons,  inclusive  trucagem,  dublagem, mixagem e congêneres.',
  },
  {
    cnae: '5912-0/02',
    cnae_desc: 'Serviços   de   mixagem   sonora   em   produção audiovisual',
    item: '13.02',
    item_desc:
      'Fonografia  ou  gravação  de  sons,  inclusive  trucagem,  dublagem, mixagem e congêneres.',
  },
  {
    cnae: '5920-1/00',
    cnae_desc: 'Atividades de gravação de som e de edição de música',
    item: '13.02',
    item_desc:
      'Fonografia  ou  gravação  de  sons,  inclusive  trucagem,  dublagem, mixagem e congêneres.',
  },
  {
    cnae: '5911-1/01',
    cnae_desc: 'Estúdios cinematográficos',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '9609-2/04',
    cnae_desc:
      'Exploração  de  máquinas  de  serviços  pessoais acionados po moeda',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '1830-0/02',
    cnae_desc: 'REPRODUCAO   DE   VIDEO   EM   QUALQUER SUPORTE',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '5912-0/99',
    cnae_desc:
      'Atividades de pós-produção cinematográfica, de vídeos   e   de   programas   de   televisão   não especificadas anteriormente',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '7420-0/01',
    cnae_desc:
      'Atividades  de  produção  de  fotografias,  exceto aérea e submarina',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '7420-0/02',
    cnae_desc: 'Atividades de produção de fotografias aéreas e submarinas',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '7420-0/03',
    cnae_desc: 'Laboratórios fotográficos',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '7420-0/04',
    cnae_desc: 'Filmagem de festas e eventos',
    item: '13.03',
    item_desc:
      'Fotografia  e  cinematografia,  inclusive  revelação,  ampliação,  cópia, reprodução, trucagem e congêneres.',
  },
  {
    cnae: '8219-9/01',
    cnae_desc: 'Fotocópias',
    item: '13.04',
    item_desc: 'Reprografia, microfilmagem e digitalização.',
  },
  {
    cnae: '7420-0/05',
    cnae_desc: 'Serviços de microfilmagem',
    item: '13.04',
    item_desc: 'Reprografia, microfilmagem e digitalização.',
  },
  {
    cnae: '1741-9/01',
    cnae_desc: 'Fabricação de formulários contínuos',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1811-3/01',
    cnae_desc: 'Impressão de jornais',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1811-3/02',
    cnae_desc:
      'Impressão     de     livros,     revistas     e     outras publicações periódicas',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1812-1/00',
    cnae_desc: 'Impressão de material de segurança',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1813-0/01',
    cnae_desc: 'Impressão de material para uso publicitário',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1813-0/99',
    cnae_desc: 'Impressão de material para outros usos',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1821-1/00',
    cnae_desc: 'Serviços de pré-impressão',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '1822-9/01',
    cnae_desc: 'Serviços de encadernação e plastificação',
    item: '13.05',
    item_desc:
      'Composição gráfica, fotocomposição, clicheria, zincografia, litografia, fotolitografia.',
  },
  {
    cnae: '2930-1/03',
    cnae_desc:
      'FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES     PARA     OUTROS     VEÍCULOS AUTOMOTORES,   EXCETO   CAMINHÕES   E ÔNIBUS',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3312-1/02',
    cnae_desc:
      'Manutenção    e    reparação    de    aparelhos    e instrumentos de medida, teste e controle',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3312-1/03',
    cnae_desc:
      'Manutenção     e     reparação     de     aparelhos eletromédicos        e        eletroterapêuticos        e equipamentos de irradiação',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3312-1/04',
    cnae_desc:
      'Manutenção  e  reparação  de  equipamentos  e instrumentos ópticos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3313-9/01',
    cnae_desc:
      'Manutenção     e     reparação     de     geradores, transformadores e motores elétricos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3313-9/02',
    cnae_desc:
      'Manutenção    e    reparação    de    baterias    e acumuladores elétricos, exceto para veículos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/01',
    cnae_desc: 'Manutenção e reparação de máquinas motrizes não-elétricas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/02',
    cnae_desc:
      'Manutenção   e   reparação   de   equipamentos hidráulicos e pneumáticos, exceto válvulas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/03',
    cnae_desc: 'Manutenção e reparação de válvulas industriais',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/04',
    cnae_desc: 'Manutenção e reparação de compressores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/05',
    cnae_desc:
      'Manutenção  e  reparação  de  equipamentos  de transmissão para fins industriais',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/06',
    cnae_desc:
      'Manutenção     e     reparação     de     máquinas, aparelhos   e   equipamentos   para   instalações térmicas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/07',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e aparelhos de refrigeração e ventilação para uso industrial e comercial',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/08',
    cnae_desc:
      'Manutenção     e     reparação     de     máquinas, equipamentos  e  aparelhos  para  transporte  e elevação de cargas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/09',
    cnae_desc:
      'Manutenção   e   reparação   de   máquinas   de escrever, calcular e de outros equipamentos não eletrônicos para escritório',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/10',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos para uso geral não especificados anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/11',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos para agricultura e pecuária',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/12',
    cnae_desc: 'Manutenção e reparação de tratores agrícolas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/13',
    cnae_desc: 'Manutenção     e     reparação     de     máquinas- ferramenta',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/14',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos para a prospecção e extração de petróleo',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/15',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos  para  uso  na  extração  mineral, exceto na extração de petróleo',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/16',
    cnae_desc: 'Manutenção  e  reparação  de   tratores,  exceto agrícolas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/17',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos  de  terraplenagem,  pavimentação e construção, exceto tratores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/18',
    cnae_desc:
      'Manutenção  e  reparação  de  máquinas  para  a indústria      metalúrgica,      exceto      máquinas- ferramenta',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/19',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos  para  as  indústrias  de  alimentos, bebidas e fumo',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/20',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e equipamentos    para    a    indústria    têxtil,    do vestuário, do couro e calçados',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/21',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e aparelhos  para  a  indústria  de  celulose,  papel e papelão e artefatos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/22',
    cnae_desc:
      'Manutenção    e    reparação    de    máquinas    e aparelhos para a indústria do plástico',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3316-3/01',
    cnae_desc:
      'Manutenção  e  reparação  de  aeronaves,  exceto a manutenção na pista',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3316-3/02',
    cnae_desc: 'Manutenção de aeronaves na pista',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3317-1/01',
    cnae_desc:
      'Manutenção   e   reparação   de   embarcações   e estruturas flutuantes',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3317-1/02',
    cnae_desc: 'Manutenção e reparação de embarcações para esporte e lazer',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4322-3/02',
    cnae_desc:
      'Instalação  e  manutenção  de  sistemas  centrais de ar condicionado, de ventilação e refrigeração',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4322-3/03',
    cnae_desc: 'Instalações   de   sistema   de   prevenção   contra incêndio',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4329-1/03',
    cnae_desc:
      'Instalação,     manutenção     e     reparação     de elevadores, escadas e esteiras rolantes, exceto de fabricação própria',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4329-1/04',
    cnae_desc:
      'Montagem     e     instalação     de     sistemas     e equipamentos  de  iluminação  e  sinalização  em vias públicas, portos e aeroportos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4329-1/05',
    cnae_desc: 'Tratamentos térmicos, acústicos ou de vibração',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4751-2/02',
    cnae_desc: 'Recarga  de  cartuchos  para  equipamentos  de informática',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '7490-1/02',
    cnae_desc: 'Escafandria e mergulho',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/01',
    cnae_desc: 'Reparação  de  calçados,  bolsas  e  artigos  de viagem',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/03',
    cnae_desc: 'Reparação de relógios',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/04',
    cnae_desc:
      'Reparação   de   bicicletas,   triciclos   e   outros veículos não-motorizados',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/05',
    cnae_desc: 'Reparação de artigos do mobiliário',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/06',
    cnae_desc: 'Reparação de jóias',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3311-2/00',
    cnae_desc:
      'Manutenção      e      reparação      de      tanques, reservatórios metálicos e caldeiras, exceto para veículos',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3313-9/99',
    cnae_desc:
      'Manutenção     e     reparação     de     máquinas, aparelhos       e       materiais       elétricos       não especificados anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3314-7/99',
    cnae_desc:
      'Manutenção e reparação de outras máquinas e equipamentos     para     usos     industriais     não especificados anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3315-5/00',
    cnae_desc: 'Manutenção e reparação de veículos ferroviários',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '3319-8/00',
    cnae_desc:
      'Manutenção  e  reparação  de  equipamentos  e produtos não especificados anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4520-0/01',
    cnae_desc:
      'Serviços de manutenção e reparação mecânica de veículos automotores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4520-0/03',
    cnae_desc:
      'Serviços de manutenção e reparação elétrica de veículos automotores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4520-0/04',
    cnae_desc:
      'Serviços  de  alinhamento  e  balanceamento  de veículos automotores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4520-0/05',
    cnae_desc:
      'Serviços  de  lavagem,  lubrificação  e  polimento de veículos automotores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4520-0/07',
    cnae_desc:
      'Serviços de instalação, manutenção e reparação de acessórios para veículos automotores',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '4543-9/00',
    cnae_desc: 'Manutenção   e   reparação   de   motocicletas   e motonetas',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '6190-6/99',
    cnae_desc:
      'Outras   atividades   de   telecomunicações   não especificadas anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9512-6/00',
    cnae_desc: 'Reparação  e  manutenção  de  equipamentos  de comunicação',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9521-5/00',
    cnae_desc:
      'Reparação   e   manutenção   de   equipamentos eletroeletrônicos de uso pessoal e doméstico',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9529-1/99',
    cnae_desc:
      'Reparação  e  manutenção  de  outros  objetos  e equipamentos    pessoais    e    domésticos    não especificados anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9609-2/99',
    cnae_desc:
      'Outras   atividades   de   serviços   pessoais   não especificadas anteriormente',
    item: '14.01',
    item_desc:
      'Lubrificação,  limpeza,  lustração,  revisão,  carga  e  recarga,  conserto, restauração,  blindagem,  manutenção  e  conservação  de  máquinas, veículos,   aparelhos,   equipamentos,   motores,   elevadores   ou   de qualquer  objeto  (exceto  peças  e  partes  empregadas,  que  ficam sujeitas ao ICMS).',
  },
  {
    cnae: '9511-8/00',
    cnae_desc:
      'Reparação  e  manutenção  de  computadores  e de equipamentos periféricos',
    item: '14.02',
    item_desc: 'Assistência técnica.',
  },
  {
    cnae: '9512-6/00',
    cnae_desc: 'Reparação  e  manutenção  de  equipamentos  de comunicação',
    item: '14.02',
    item_desc: 'Assistência técnica.',
  },
  {
    cnae: '2950-6/00',
    cnae_desc:
      'Recondicionamento  e  recuperação  de  motores para veículos automotores',
    item: '14.03',
    item_desc:
      'Recondicionamento de motores (exceto peças e partes empregadas, que ficam sujeitas ao ICMS).',
  },
  {
    cnae: '2212-9/00',
    cnae_desc: 'Reforma de pneumáticos usados',
    item: '14.04',
    item_desc: 'Recauchutagem ou regeneração de pneus.',
  },
  {
    cnae: '4520-0/06',
    cnae_desc:
      'Serviços     de     borracharia     para     veículos automotores',
    item: '14.04',
    item_desc: 'Recauchutagem ou regeneração de pneus.',
  },
  {
    cnae: '2391-5/01',
    cnae_desc:
      'BRITAMENTO      DE      PEDRAS,      EXCETO ASSOCIADO À EXTRAÇÃO',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2391-5/02',
    cnae_desc:
      'APARELHAMENTO      DE      PEDRAS      PARA CONSTRUÇÃO,    EXCETO    ASSOCIADO    À EXTRAÇÃO',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2391-5/03',
    cnae_desc:
      'APARELHMANTO  DE  PLACAS  E  EXECUÇÃO DE  TRABALHOS  EM  MÁRMORE,  GRANITO, ARDÓSIA E OUTRAS PEDRAS',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2599-3/02',
    cnae_desc: 'Serviços de corte e dobra de metais',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2722-8/02',
    cnae_desc:
      'Recondicionamento de baterias e acumuladores para veículos automotores',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '3250-7/09',
    cnae_desc: 'Serviços de laboratórios ópticos',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '9002-7/02',
    cnae_desc: 'Restauração de obras de arte',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '9529-1/05',
    cnae_desc: 'Reparação de artigos do mobiliário',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '1340-5/01',
    cnae_desc:
      'ESTAMPARIA  E  TEXTURIZACAO   EM  FIOS, TECIDOS,  ARTEFATOS  TEXTEIS  E  PECAS DO VESTUARIO',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2539-0/01',
    cnae_desc: 'Serviços de usinagem, tornearia e solda',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '2539-0/02',
    cnae_desc: 'Serviços   de   tratamento   e   revestimento   em metais',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '4520-0/02',
    cnae_desc:
      'Serviços  de  lanternagem  ou  funilaria  e  pintura de veículos automotores',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '4520-0/05',
    cnae_desc:
      'Serviços  de  lavagem,  lubrificação  e  polimento de veículos automotores',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '8292-0/00',
    cnae_desc: 'Envasamento e empacotamento sob contrato',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '9609-2/99',
    cnae_desc:
      'Outras   atividades   de   serviços   pessoais   não especificadas anteriormente',
    item: '14.05',
    item_desc:
      'Restauração,      recondicionamento,      acondicionamento,      pintura, beneficiamento,    lavagem,    secagem,    tingimento,    galvanoplastia, anodização, corte, recorte, polimento, plastificação e congêneres, de objetos quaisquer.',
  },
  {
    cnae: '4322-3/03',
    cnae_desc: 'Instalações   de   sistema   de   prevenção   contra incêndio',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '4329-1/02',
    cnae_desc:
      'Instalação  de  equipamentos  para  orientação  à navegação marítima, fluvial e lacustre',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '4329-1/04',
    cnae_desc:
      'Montagem     e     instalação     de     sistemas     e equipamentos  de  iluminação  e  sinalização  em vias públicas, portos e aeroportos',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '3321-0/00',
    cnae_desc: 'Instalação    de    máquinas    e    equipamentos industriais',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '3329-5/99',
    cnae_desc:
      'Instalação     de     outros     equipamentos     não especificados anteriormente',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '6190-6/99',
    cnae_desc:
      'Outras   atividades   de   telecomunicações   não especificadas anteriormente',
    item: '14.06',
    item_desc:
      'Instalação  e  montagem  de  aparelhos,  máquinas  e  equipamentos, inclusive     montagem     industrial,     prestados     ao     usuário     final, exclusivamente com material por ele fornecido.',
  },
  {
    cnae: '4330-4/03',
    cnae_desc: 'Obras de acabamento em gesso e estuque',
    item: '14.07',
    item_desc: 'Colocação de molduras e congêneres.',
  },
  {
    cnae: '1629-3/01',
    cnae_desc:
      'Fabricação  de  artefatos  diversos  de  madeira, exceto móveis',
    item: '14.07',
    item_desc: 'Colocação de molduras e congêneres.',
  },
  {
    cnae: '1822-9/99',
    cnae_desc:
      'Serviços    de    acabamentos    gráficos,    exceto encadernação e plastificação',
    item: '14.08',
    item_desc:
      'Encadernação, gravação e douração de livros, revistas e congêneres.',
  },
  {
    cnae: '1340-5/99',
    cnae_desc:
      'Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1411-8/02',
    cnae_desc: 'Facção de roupas íntimas',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1412-6/02',
    cnae_desc:
      'Confecção, sob medida, de peças do vestuário, exceto roupas íntimas',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1412-6/03',
    cnae_desc: 'Facção  de  peças  do  vestuário,  exceto  roupas íntimas',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1413-4/01',
    cnae_desc: 'CONFECCAO  DE  ROUPAS  PROFISSIONAIS, EXCETO SOB MEDIDA',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1413-4/02',
    cnae_desc: 'Confecção, sob medida, de roupas profissionais',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1413-4/03',
    cnae_desc: 'Facção de roupas profissionais',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '1531-9/02',
    cnae_desc: 'Acabamento de calçados de couro sob contrato',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '9529-1/99',
    cnae_desc:
      'Reparação  e  manutenção  de  outros  objetos  e equipamentos    pessoais    e    domésticos    não especificados anteriormente',
    item: '14.09',
    item_desc:
      'Alfaiataria  e  costura,  quando  o  material  for  fornecido  pelo  usuário final, exceto aviamento.',
  },
  {
    cnae: '9601-7/01',
    cnae_desc: 'Lavanderias',
    item: '14.10',
    item_desc: 'Tinturaria e lavanderia.',
  },
  {
    cnae: '9601-7/02',
    cnae_desc: 'Tinturarias',
    item: '14.10',
    item_desc: 'Tinturaria e lavanderia.',
  },
  {
    cnae: '9601-7/03',
    cnae_desc: 'Toalheiros',
    item: '14.10',
    item_desc: 'Tinturaria e lavanderia.',
  },
  {
    cnae: '1340-5/02',
    cnae_desc:
      'Alvejamento,   tingimento   e   torção   em   fios, tecidos, artefatos têxteis e peças do vestuário',
    item: '14.10',
    item_desc: 'Tinturaria e lavanderia.',
  },
  {
    cnae: '1340-5/99',
    cnae_desc:
      'Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário',
    item: '14.10',
    item_desc: 'Tinturaria e lavanderia.',
  },
  {
    cnae: '9529-1/05',
    cnae_desc: 'Reparação de artigos do mobiliário',
    item: '14.11',
    item_desc: 'Tapeçaria e reforma de estofamentos em geral.',
  },
  {
    cnae: '4520-0/08',
    cnae_desc: 'Serviços de capotaria',
    item: '14.11',
    item_desc: 'Tapeçaria e reforma de estofamentos em geral.',
  },
  {
    cnae: '4520-0/02',
    cnae_desc:
      'Serviços  de  lanternagem  ou  funilaria  e  pintura de veículos automotores',
    item: '14.12',
    item_desc: 'Funilaria e lanternagem.',
  },
  {
    cnae: '2399-1/01',
    cnae_desc:
      'Decoração,  lapidação,  gravação,  vitrificação  e outros  trabalhos  em  cerâmica,  louça,  vidro  e cristal',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '2599-3/01',
    cnae_desc:
      'Serviços  de  confecção  de  armações  metálicas para a construção',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '3329-5/01',
    cnae_desc: 'Serviços  de  montagem  de  móveis  de  qualquer material',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '4330-4/02',
    cnae_desc:
      'Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '1622-6/99',
    cnae_desc: 'Fabricação de outros artigos de carpintaria para construção',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '2512-8/00',
    cnae_desc: 'Fabricação de esquadrias de metal',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '2542-0/00',
    cnae_desc:
      'Fabricação   de   artigos   de   serralheria,   exceto esquadrias',
    item: '14.13',
    item_desc: 'Carpintaria e serralheria.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6424-7/02',
    cnae_desc: 'COOPERATIVAS CENTRAIS DE CREDITO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6435-2/03',
    cnae_desc: 'COMPANHIAS HIPOTECARIAS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6470-1/01',
    cnae_desc:
      'FUNDOS     DE     INVESTIMENTO,     EXCETO PREVIDENCIÁRIOS E IMOBILIÁRIOS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6470-1/02',
    cnae_desc:
      'FUNDOS                DE                INVESTIMENTO PREVIDENCIARIOS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6470-1/03',
    cnae_desc: 'FUNDOS DE INVESTIMENTO IMOBILIARIOS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6499-9/01',
    cnae_desc: 'CLUBES DE INVESTIMENTO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6499-9/02',
    cnae_desc: 'SOCIEDADES DE INVESTIMENTO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6619-3/05',
    cnae_desc: 'OPERADORAS DE CARTOES DE DEBITO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6432-8/00',
    cnae_desc: 'BANCOS DE INVESTIMENTO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6433-6/00',
    cnae_desc: 'BANCOS DE DESENVOLVIMENTO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6434-4/00',
    cnae_desc: 'AGENCIAS DE FOMENTO',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6613-4/00',
    cnae_desc: 'Administração de cartões de crédito',
    item: '15.01',
    item_desc:
      'Administração  de  fundos  quaisquer,  de  consórcio,  de  cartão  de crédito  ou  débito  e  congêneres,  de  carteira  de  clientes,  de  cheques pré-datados e congêneres.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6435-2/02',
    cnae_desc: 'ASSOCIACOES        DE        POUPANCA        E EMPRESTIMO',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6433-6/00',
    cnae_desc: 'BANCOS DE DESENVOLVIMENTO',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6450-6/00',
    cnae_desc: 'SOCIEDADES DE CAPITALIZACAO',
    item: '15.02',
    item_desc:
      'Abertura  de  contas  em  geral,  inclusive  conta-corrente,  conta  de investimentos  e  aplicação  e  caderneta  de  poupança,  no  País  e  no exterior,  bem  como  a  manutenção  das  referidas  contas  ativas  e inativas.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.03',
    item_desc:
      'Locação    e    manutenção    de    cofres    particulares,    de    terminais eletrônicos,  de terminais de atendimento e de bens e equipamentos em geral.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.04',
    item_desc:
      'Fornecimento ou emissão de atestados  em  geral,  inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.05',
    item_desc:
      'Cadastro,   elaboração   de   ficha   cadastral,   renovação   cadastral   e congêneres,  inclusão  ou  exclusão  no  Cadastro  de  Emitentes  de Cheques   sem   Fundos   -   CCF   ou   em   quaisquer   outros   bancos cadastrais.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.06',
    item_desc:
      'Emissão,   reemissão   e   fornecimento   de   avisos,   comprovantes   e documentos   em   geral;   abono   de   firmas;   coleta   e   entrega   de documentos, bens e valores; comunicação com outra agência ou com a   administração   central;   licenciamento   eletrônico   de   veículos; transferência  de  veículos;  agenciamento  fiduciário  ou  depositário; devolução de bens em custódia.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6435-2/03',
    cnae_desc: 'COMPANHIAS HIPOTECARIAS',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6619-3/04',
    cnae_desc: 'CAIXAS ELETRONICOS',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6432-8/00',
    cnae_desc: 'BANCOS DE INVESTIMENTO',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6433-6/00',
    cnae_desc: 'BANCOS DE DESENVOLVIMENTO',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6450-6/00',
    cnae_desc: 'SOCIEDADES DE CAPITALIZACAO',
    item: '15.07',
    item_desc:
      'Acesso,  movimentação,  atendimento  e  consulta  a  contas  em  geral, por  qualquer  meio  ou  processo,  inclusive  por  telefone,  fac-símile, internet e telex, acesso a terminais de atendimento, inclusive vinte e quatro   horas;   acesso   a   outro   banco   e   a   rede   compartilhada; fornecimento  de  saldo,  extrato  e  demais  informações  relativas  a contas em geral, por qualquer meio ou processo.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6435-2/02',
    cnae_desc: 'ASSOCIACOES        DE        POUPANCA        E EMPRESTIMO',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6435-2/03',
    cnae_desc: 'COMPANHIAS HIPOTECARIAS',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6499-9/05',
    cnae_desc: 'CONCESSAO DE CREDITO PELAS OSCIP',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6432-8/00',
    cnae_desc: 'BANCOS DE INVESTIMENTO',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6433-6/00',
    cnae_desc: 'BANCOS DE DESENVOLVIMENTO',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6436-1/00',
    cnae_desc:
      'SOCIEDADES                DE                CREDITO, FINANCIAMENTO      E      INVESTIMENTO      - FINANCEIRAS',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6437-9/00',
    cnae_desc:
      'SOCIEDADES         DE         CREDITO         AO MICROEMPREENDEDOR',
    item: '15.08',
    item_desc:
      'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro   de   contrato   de   crédito;   estudo,   análise   e   avaliação   de operações de crédito; emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres; serviços relativos à abertura de crédito, para quaisquer fins.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6440-9/00',
    cnae_desc: 'ARRENDAMENTO MERCANTIL',
    item: '15.09',
    item_desc:
      'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de   direitos   e   obrigações,   substituição   de   garantia,   alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6619-3/01',
    cnae_desc: 'SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6619-3/02',
    cnae_desc: 'CORRESPONDENTES     DE     INSTITUICOES FINANCEIRAS',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.10',
    item_desc:
      'Serviços relacionados a cobranças, recebimentos ou pagamentos em geral,  de  títulos  quaisquer,  de  contas  ou  carnês,  de  câmbio,  de tributos  e  por  conta  de  terceiros,  inclusive  os  efetuados  por  meio eletrônico, automático ou por máquinas de atendimento; fornecimento de  posição  de  cobrança  recebimento  ou  pagamento;  emissão  de carnês, fichas de compensação, impressos e documentos em geral.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.11',
    item_desc:
      'Devolução   de   títulos,   protesto   de   títulos,   sustação   de   protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6619-3/01',
    cnae_desc: 'SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6499-9/99',
    cnae_desc:
      'OUTRAS      ATIVIDADES      DE      SERVICOS FINANCEIROS         NAO         ESPECIFICADAS ANTERIORMENTE',
    item: '15.12',
    item_desc: 'Custódia em geral, inclusive de títulos e valores mobiliários.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6438-7/01',
    cnae_desc: 'BANCOS DE CAMBIO',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6438-7/99',
    cnae_desc:
      'OUTRAS                 INSTITUICOES                DE INTERMEDIACAO     NÃO-MONETARIA     NAO ESPECIFIDAS ANTERIORMENTE',
    item: '15.13',
    item_desc:
      'Serviços  relacionados  a  operações  de  câmbio  em  geral,  edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio; emissão   de   registro   de   exportação   ou   de   crédito;   cobrança   ou depósito   no   exterior;   emissão,   fornecimento   e   cancelamento   de cheques  de  viagem;  fornecimento,  transferência,  cancelamento  e demais   serviços   relativos   à   carta   de   crédito   de   importação, exportação e garantias recebidas; envio e recebimento de mensagens em geral relacionadas a operações de câmbio.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.14',
    item_desc:
      'Fornecimento,   emissão,   reemissão,   renovação  e   manutenção  de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.15',
    item_desc:
      'Compensação de cheques e títulos quaisquer; serviços relacionados a   depósito,   inclusive   depósito   identificado,   a   saque   de   contas quaisquer,  por  qualquer  meio  ou  processo,  inclusive  em  terminais eletrônicos e de atendimento.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6432-8/00',
    cnae_desc: 'BANCOS DE INVESTIMENTO',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6433-6/00',
    cnae_desc: 'BANCOS DE DESENVOLVIMENTO',
    item: '15.16',
    item_desc:
      'Emissão, reemissão, liquidação, alteração,  cancelamento e baixa de ordens  de  pagamento,  ordens  de  crédito  e  similares,  por  qualquer meio  ou  processo;  serviços  relacionados  à  transferência  de  valores, dados,  fundos,  pagamentos  e  similares,  inclusive  entre  contas  em geral.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.17',
    item_desc:
      'Emissão,    fornecimento,    devolução,    sustação,    cancelamento    e oposição de cheques quaisquer, avulso ou por talão.',
  },
  {
    cnae: '6424-7/01',
    cnae_desc: 'BANCOS COOPERATIVOS',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6424-7/03',
    cnae_desc: 'COOPERATIVAS DE CREDITO MUTUO',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6424-7/04',
    cnae_desc: 'COOPERATIVAS DE CREDITO RURAL',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6435-2/01',
    cnae_desc: 'SOCIEDADES DE CREDITO IMOBILIARIO',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6435-2/02',
    cnae_desc: 'ASSOCIACOES        DE        POUPANCA        E EMPRESTIMO',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6435-2/03',
    cnae_desc: 'COMPANHIAS HIPOTECARIAS',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6499-9/04',
    cnae_desc: 'CAIXAS        DE        FINANCIAMENTO        DE CORPORACOES',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6421-2/00',
    cnae_desc: 'BANCOS COMERCIAIS',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6422-1/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     COM     CARTEIRA COMERCIAL',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6423-9/00',
    cnae_desc: 'CAIXAS ECONOMICAS',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '6431-0/00',
    cnae_desc: 'BANCOS     MULTIPLOS,     SEM     CARTEIRA COMERCIAL',
    item: '15.18',
    item_desc:
      'Serviços  relacionados  a  crédito  imobiliário,  avaliação  e  vistoria  de imóvel   ou   obra,   análise   técnica   e   jurídica,   emissão,   reemissão, alteração,   transferência   e   renegociação   de   contrato,   emissão   e reemissão  do  termo  de  quitação  e  demais  serviços  relacionados  a crédito imobiliário.',
  },
  {
    cnae: '4912-4/02',
    cnae_desc:
      'Transporte  ferroviário  de  passageiros  municipal e em região metropolitana',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4912-4/03',
    cnae_desc: 'Transporte metroviário',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4921-3/01',
    cnae_desc:
      'Transporte  rodoviário  coletivo  de  passageiros, com itinerário fixo, municipal',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4929-9/01',
    cnae_desc:
      'Transporte  rodoviário  coletivo  de  passageiros, sob regime de fretamento, municipal',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4930-2/01',
    cnae_desc:
      'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4930-2/03',
    cnae_desc: 'Transporte rodoviário de produtos perigosos',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4930-2/04',
    cnae_desc: 'Transporte rodoviário de mudanças',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5021-1/01',
    cnae_desc:
      'Transporte   por   navegação   interior   de   carga, municipal, exceto travessia',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5091-2/01',
    cnae_desc:
      'Transporte     por     navegação     de     travessia, municipal',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5099-8/01',
    cnae_desc: 'Transporte aquaviário para passeios turísticos',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4923-0/01',
    cnae_desc: 'Serviço de táxi',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4923-0/02',
    cnae_desc:
      'Serviço de transporte de passageiros  -  locação de automóveis com motorista',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4924-8/00',
    cnae_desc: 'Transporte escolar',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4940-0/00',
    cnae_desc: 'TRANSPORTE DUTOVIÁRIO',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '4950-7/00',
    cnae_desc: 'Trens turísticos, teleféricos e similares',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5022-0/01',
    cnae_desc:
      'Transporte      por      navegação      interior      de passageiros   em   linhas   regulares,   municipal, exceto travessia',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5112-9/99',
    cnae_desc:
      'Outros    serviços    de    transporte    aéreo    de passageiros não-regular',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '5229-0/02',
    cnae_desc: 'Serviços de reboque de veículos',
    item: '16.01',
    item_desc: 'Serviços de transporte de natureza municipal.',
  },
  {
    cnae: '6920-6/02',
    cnae_desc:
      'Atividades  de  consultoria  e  auditoria  contábil  e tributária',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7490-1/03',
    cnae_desc:
      'Serviços   de   agronomia   e   de   consultoria   às atividades agrícolas e pecuárias',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '8299-7/01',
    cnae_desc: 'Medição de consumo de energia elétrica, gás e água',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '0162-8/99',
    cnae_desc:
      'ATIVIDADES  DE  APOIO  A  PECUARIA  NAO ESPECIFICADAS ANTERIORMENTE',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '0311-6/04',
    cnae_desc: 'ATIVIDADES  DE  APOIO  A  PESCA  EM  AGUA SALGADA',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '0312-4/04',
    cnae_desc: 'ATIVIDADES  DE  APOIO  A  PESCA  EM  AGUA DOCE',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '0321-3/05',
    cnae_desc: 'ATIVIDADES DE APOIO A AQUICULTURA EM AGUA SALGADA E SALOBRA',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '0322-1/07',
    cnae_desc: 'Atividades de apoio à aquicultura em água doce',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '6399-2/00',
    cnae_desc:
      'Outras  atividades  de  prestação  de  serviços  de informação não especificadas anteriormente',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7020-4/00',
    cnae_desc:
      'Atividades      de      consultoria      em      gestão empresarial,      exceto      consultoria      técnica específica',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7319-0/04',
    cnae_desc: 'Consultoria em publicidade',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7320-3/00',
    cnae_desc: 'Pesquisas de mercado e de opinião pública',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '9430-8/00',
    cnae_desc: 'Atividades  de  associação  de  defesa  de  direitos sociais',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '9609-2/99',
    cnae_desc:
      'Outras   atividades   de   serviços   pessoais   não especificadas anteriormente',
    item: '17.01',
    item_desc:
      'Assessoria  ou  consultoria  de  qualquer  natureza,  não  contida  em outros itens desta lista; análise, exame, pesquisa, coleta, compilação e   fornecimento   de   dados   e   informações   de   qualquer   natureza, inclusive cadastro e similares.',
  },
  {
    cnae: '7490-1/01',
    cnae_desc: 'Serviços de tradução, interpretação e similares',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5229-0/01',
    cnae_desc:
      'Serviços   de   apoio   ao   transporte   por   táxi, inclusive centrais de chamada',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5811-5/00',
    cnae_desc: 'Edição de livros',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5812-3/00',
    cnae_desc: 'Edição de jornais',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5813-1/00',
    cnae_desc: 'Edição de revistas',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5819-1/00',
    cnae_desc: 'Edição  de  cadastros,  listas  e  outros  produtos gráficos',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5821-2/00',
    cnae_desc: 'Edição integrada à impressão de livros',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5822-1/00',
    cnae_desc: 'Edição integrada à impressão de jornais',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5823-9/00',
    cnae_desc: 'Edição integrada à impressão de revistas',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '5829-8/00',
    cnae_desc:
      'Edição   integrada   à   impressão   de   cadastros, listas e outros produtos gráficos',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '8211-3/00',
    cnae_desc:
      'Serviços   combinados   de   escritório   e   apoio administrativo',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '8219-9/99',
    cnae_desc:
      'Preparação     de     documentos     e     serviços especializados   de   apoio   administrativo   não especificados anteriormente',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '8220-2/00',
    cnae_desc: 'Atividades de teleatendimento',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '8299-7/99',
    cnae_desc:
      'Outras     atividades     de     serviços     prestados principalmente  às  empresas  não  especificadas anteriormente',
    item: '17.02',
    item_desc:
      'Datilografia, digitação, estenografia, expediente, secretaria em geral, resposta  audível,  redação,  edição,  interpretação,  revisão,  tradução, apoio e infra estrutura administrativa e congêneres.',
  },
  {
    cnae: '7020-4/00',
    cnae_desc:
      'Atividades      de      consultoria      em      gestão empresarial,      exceto      consultoria      técnica específica',
    item: '17.03',
    item_desc:
      'Planejamento,  coordenação,  programação  ou  organização  técnica, financeira ou administrativa.',
  },
  {
    cnae: '8211-3/00',
    cnae_desc:
      'Serviços   combinados   de   escritório   e   apoio administrativo',
    item: '17.03',
    item_desc:
      'Planejamento,  coordenação,  programação  ou  organização  técnica, financeira ou administrativa.',
  },
  {
    cnae: '7810-8/00',
    cnae_desc: 'Seleção e agenciamento de mão-de-obra',
    item: '17.04',
    item_desc: 'Recrutamento, agenciamento, seleção e colocação de mão de obra',
  },
  {
    cnae: '7820-5/00',
    cnae_desc: 'Locação de mão-de-obra temporária',
    item: '17.05',
    item_desc:
      'Fornecimento   de   mão   de   obra,   mesmo   em   caráter   temporário, inclusive  de  empregados  ou  trabalhadores,  avulsos  ou  temporários, contratados pelo prestador de serviço.',
  },
  {
    cnae: '7830-2/00',
    cnae_desc: 'Fornecimento  e  gestão  de  recursos  humanos para terceiros',
    item: '17.05',
    item_desc:
      'Fornecimento   de   mão   de   obra,   mesmo   em   caráter   temporário, inclusive  de  empregados  ou  trabalhadores,  avulsos  ou  temporários, contratados pelo prestador de serviço.',
  },
  {
    cnae: '8111-7/00',
    cnae_desc:
      'Serviços   combinados   para   apoio   a   edifícios, exceto condomínios prediais',
    item: '17.05',
    item_desc:
      'Fornecimento   de   mão   de   obra,   mesmo   em   caráter   temporário, inclusive  de  empregados  ou  trabalhadores,  avulsos  ou  temporários, contratados pelo prestador de serviço.',
  },
  {
    cnae: '5911-1/02',
    cnae_desc: 'Produção de filmes para publicidade',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '7311-4/00',
    cnae_desc: 'Agências de publicidade',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '7319-0/01',
    cnae_desc: 'Criação e montagem de estandes para feiras e exposições',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '7319-0/02',
    cnae_desc: 'Promoção de vendas',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '7319-0/03',
    cnae_desc: 'Marketing direto',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '7319-0/99',
    cnae_desc:
      'Outras      atividades      de      publicidade      não especificadas anteriormente',
    item: '17.06',
    item_desc:
      'Propaganda    e    publicidade,    inclusive    promoção    de    vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.',
  },
  {
    cnae: '5310-5/02',
    cnae_desc: 'Atividades de franqueadas do Correio Nacional',
    item: '17.08',
    item_desc: 'Franquia (franchising).',
  },
  {
    cnae: '7740-3/00',
    cnae_desc: 'Gestão de ativos intangíveis não-financeiros',
    item: '17.08',
    item_desc: 'Franquia (franchising).',
  },
  {
    cnae: '6621-5/01',
    cnae_desc: 'Peritos e avaliadores de seguros',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '6911-7/02',
    cnae_desc: 'Atividades auxiliares da justiça',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '6920-6/02',
    cnae_desc:
      'Atividades  de  consultoria  e  auditoria  contábil  e tributária',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '7119-7/04',
    cnae_desc:
      'Serviços   de   perícia   técnica   relacionados   à segurança do trabalho',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '7112-0/00',
    cnae_desc: 'Serviços de engenharia',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '7120-1/00',
    cnae_desc: 'Testes e análises técnicas',
    item: '17.09',
    item_desc: 'Perícias, laudos, exames técnicos e análises técnicas.',
  },
  {
    cnae: '8230-0/01',
    cnae_desc:
      'Serviços de  organização de  feiras,  congressos, exposições e festas',
    item: '17.10',
    item_desc:
      'Planejamento,  organização  e  administração  de  feiras,  exposições, congressos e congêneres.',
  },
  {
    cnae: '5620-1/02',
    cnae_desc:
      'Serviços    de    alimentação    para    eventos    e recepções – bufê',
    item: '17.11',
    item_desc:
      'Organização de festas e recepções; bufê (exceto o fornecimento de alimentação e bebidas, que fica sujeito ao ICMS).',
  },
  {
    cnae: '6611-8/01',
    cnae_desc: 'Bolsa de valores',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6611-8/02',
    cnae_desc: 'Bolsa de mercadorias',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6611-8/03',
    cnae_desc: 'Bolsa de mercadorias e futuros',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6611-8/04',
    cnae_desc:
      'Administração     de     mercados     de     balcão organizados',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6493-0/00',
    cnae_desc:
      'Administração  de  consórcios  para  aquisição  de bens e direitos',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6630-4/00',
    cnae_desc:
      'Atividades   de   administração   de   fundos   por contrato ou comissão',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '6822-6/00',
    cnae_desc:
      'Gestão     e     administração     da     propriedade imobiliária',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '7740-3/00',
    cnae_desc: 'Gestão de ativos intangíveis não-financeiros',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '8660-7/00',
    cnae_desc: 'Atividades de apoio à gestão de saúde',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '9609-2/99',
    cnae_desc:
      'Outras   atividades   de   serviços   pessoais   não especificadas anteriormente',
    item: '17.12',
    item_desc:
      'Administração em geral, inclusive de bens e negócios de terceiros.',
  },
  {
    cnae: '8299-7/04',
    cnae_desc: 'Leiloeiros independentes',
    item: '17.13',
    item_desc: 'Leilão e congêneres.',
  },
  {
    cnae: '6911-7/01',
    cnae_desc: 'Serviços advocatícios',
    item: '17.14',
    item_desc: 'Advocacia.',
  },
  {
    cnae: '6911-7/02',
    cnae_desc: 'Atividades auxiliares da justiça',
    item: '17.15',
    item_desc: 'Arbitragem de qualquer espécie, inclusive jurídica.',
  },
  {
    cnae: '6621-5/01',
    cnae_desc: 'Peritos e avaliadores de seguros',
    item: '17.16',
    item_desc: 'Auditoria.',
  },
  {
    cnae: '6621-5/02',
    cnae_desc: 'Auditoria e consultoria atuarial',
    item: '17.16',
    item_desc: 'Auditoria.',
  },
  {
    cnae: '6920-6/02',
    cnae_desc:
      'Atividades  de  consultoria  e  auditoria  contábil  e tributária',
    item: '17.16',
    item_desc: 'Auditoria.',
  },
  {
    cnae: '7020-4/00',
    cnae_desc:
      'Atividades      de      consultoria      em      gestão empresarial,      exceto      consultoria      técnica específica',
    item: '17.17',
    item_desc: 'Análise de Organização e Métodos.',
  },
  {
    cnae: '6621-5/02',
    cnae_desc: 'Auditoria e consultoria atuarial',
    item: '17.18',
    item_desc: 'Atuária e cálculos técnicos de qualquer natureza.',
  },
  {
    cnae: '6920-6/01',
    cnae_desc: 'Atividades de contabilidade',
    item: '17.19',
    item_desc: 'Contabilidade, inclusive serviços técnicos e auxiliares.',
  },
  {
    cnae: '6612-6/05',
    cnae_desc: 'Agentes    de    investimentos    em    aplicações financeiras',
    item: '17.20',
    item_desc: 'Consultoria e assessoria econômica ou financeira.',
  },
  {
    cnae: '6621-5/02',
    cnae_desc: 'Auditoria e consultoria atuarial',
    item: '17.20',
    item_desc: 'Consultoria e assessoria econômica ou financeira.',
  },
  {
    cnae: '7020-4/00',
    cnae_desc:
      'Atividades      de      consultoria      em      gestão empresarial,      exceto      consultoria      técnica específica',
    item: '17.20',
    item_desc: 'Consultoria e assessoria econômica ou financeira.',
  },
  {
    cnae: '7320-3/00',
    cnae_desc: 'Pesquisas de mercado e de opinião pública',
    item: '17.21',
    item_desc: 'Estatística.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '17.21',
    item_desc: 'Estatística.',
  },
  {
    cnae: '8291-1/00',
    cnae_desc:
      'Atividades     de     cobrança     e     informações cadastrais',
    item: '17.22',
    item_desc: 'Cobrança em geral.',
  },
  {
    cnae: '6491-3/00',
    cnae_desc: 'Sociedades de fomento mercantil - factoring',
    item: '17.23',
    item_desc:
      'Assessoria,   análise,   avaliação,   atendimento,   consulta,   cadastro, seleção,  gerenciamento  de  informações,  administração  de  contas  a receber   ou   a   pagar   e   em   geral,   relacionados   a   operações   de faturização (factoring).',
  },
  {
    cnae: '8599-6/99',
    cnae_desc:
      'Outras  atividades  de  ensino  não  especificadas anteriormente',
    item: '17.24',
    item_desc:
      'Apresentação de palestras, conferências, seminários e congêneres.',
  },
  {
    cnae: '6621-5/01',
    cnae_desc: 'Peritos e avaliadores de seguros',
    item: '18.01',
    item_desc:
      'Serviços de regulação de sinistros vinculados a contratos de seguros; inspeção   e   avaliação   de   riscos  para   cobertura  de   contratos  de seguros; prevenção e gerência de riscos seguráveis e congêneres.',
  },
  {
    cnae: '6629-1/00',
    cnae_desc:
      'Atividades      auxiliares      dos      seguros,      da previdência   complementar   e   dos   planos   de saúde não especificadas anteriorme',
    item: '18.01',
    item_desc:
      'Serviços de regulação de sinistros vinculados a contratos de seguros; inspeção   e   avaliação   de   riscos  para   cobertura  de   contratos  de seguros; prevenção e gerência de riscos seguráveis e congêneres.',
  },
  {
    cnae: '8299-7/06',
    cnae_desc: 'Casas lotéricas',
    item: '19.01',
    item_desc:
      'Serviços  de  distribuição  e  venda  de  bilhetes  e  demais  produtos  de loteria,   bingos,   cartões,   pules   ou   cupons   de   apostas,   sorteios, prêmios,   inclusive   os   decorrentes   de   títulos   de   capitalização   e congêneres.',
  },
  {
    cnae: '9200-3/01',
    cnae_desc: 'Casas de bingo',
    item: '19.01',
    item_desc:
      'Serviços  de  distribuição  e  venda  de  bilhetes  e  demais  produtos  de loteria,   bingos,   cartões,   pules   ou   cupons   de   apostas,   sorteios, prêmios,   inclusive   os   decorrentes   de   títulos   de   capitalização   e congêneres.',
  },
  {
    cnae: '9200-3/99',
    cnae_desc:
      'Exploração  de  jogos  de  azar  e  apostas  não especificados anteriormente',
    item: '19.01',
    item_desc:
      'Serviços  de  distribuição  e  venda  de  bilhetes  e  demais  produtos  de loteria,   bingos,   cartões,   pules   ou   cupons   de   apostas,   sorteios, prêmios,   inclusive   os   decorrentes   de   títulos   de   capitalização   e congêneres.',
  },
  {
    cnae: '5030-1/01',
    cnae_desc: 'Navegação de apoio marítimo',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5030-1/02',
    cnae_desc: 'Navegação de apoio portuário',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5211-7/01',
    cnae_desc: 'Armazéns gerais - emissão de warrant',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5231-1/01',
    cnae_desc: 'Administração da infra-estrutura portuária',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5231-1/02',
    cnae_desc: 'Operações de terminais',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5250-8/05',
    cnae_desc: 'OPERADOR DE TRANSPORTE MULTIMODAL OTM',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '0311-6/04',
    cnae_desc: 'ATIVIDADES  DE  APOIO  A  PESCA  EM  AGUA SALGADA',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '0312-4/04',
    cnae_desc: 'ATIVIDADES  DE  APOIO  A  PESCA  EM  AGUA DOCE',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '0321-3/05',
    cnae_desc: 'ATIVIDADES DE APOIO A AQUICULTURA EM AGUA SALGADA E SALOBRA',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5211-7/99',
    cnae_desc:
      'Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5212-5/00',
    cnae_desc: 'Carga e descarga',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5239-7/00',
    cnae_desc:
      'Atividades auxiliares dos transportes aquaviários não especificadas anteriormente',
    item: '20.01',
    item_desc:
      'Serviços      portuários,      ferroportuários,      utilização      de      porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro,    atracação,    desatracação,    serviços    de    praticagem, capatazia, armazenagem de qualquer natureza,  serviços acessórios, movimentação   de   mercadorias,   serviços   de   apoio   marítimo,   de movimentação  ao  largo,  serviços  de  armadores,  estiva,  conferência, logística e congêneres.',
  },
  {
    cnae: '5240-1/01',
    cnae_desc:
      'Operação    dos    aeroportos    e    campos    de aterrissagem',
    item: '20.02',
    item_desc:
      'Serviços aeroportuários, utilização de aeroporto, movimentação de passageiros, armazenagem de qualquer natureza, capatazia, movimentação de aeronaves, serviços de apoio aeroportuários, serviços acessórios, movimentação de mercadorias, logística e congêneres.',
  },
  {
    cnae: '5250-8/05',
    cnae_desc: 'OPERADOR DE TRANSPORTE MULTIMODAL OTM',
    item: '20.02',
    item_desc:
      'Serviços aeroportuários, utilização de aeroporto, movimentação de passageiros, armazenagem de qualquer natureza, capatazia, movimentação de aeronaves, serviços de apoio aeroportuários, serviços acessórios, movimentação de mercadorias, logística e congêneres.',
  },
  {
    cnae: '5240-1/99',
    cnae_desc:
      'Atividades   auxiliares   dos   transportes   aéreos, exceto  operação  dos  aeroportos  e  campos  de aterrissagem',
    item: '20.02',
    item_desc:
      'Serviços aeroportuários, utilização de aeroporto, movimentação de passageiros, armazenagem de qualquer natureza, capatazia, movimentação de aeronaves, serviços de apoio aeroportuários, serviços acessórios, movimentação de mercadorias, logística e congêneres.',
  },
  {
    cnae: '5250-8/05',
    cnae_desc: 'OPERADOR DE TRANSPORTE MULTIMODAL OTM',
    item: '20.03',
    item_desc:
      'Serviços    de    Terminais    rodoviários,    ferroviários,    metroviários, movimentação     de     passageiros,     mercadorias,     inclusive     suas operações logísticas e congêneres',
  },
  {
    cnae: '5222-2/00',
    cnae_desc: 'Terminais rodoviários e ferroviários',
    item: '20.03',
    item_desc:
      'Serviços    de    Terminais    rodoviários,    ferroviários,    metroviários, movimentação     de     passageiros,     mercadorias,     inclusive     suas operações logísticas e congêneres',
  },
  {
    cnae: '6912-5/00',
    cnae_desc: 'Cartórios',
    item: '21.01',
    item_desc: 'Serviços de registros públicos, cartorários e notariais.',
  },
  {
    cnae: '5221-4/00',
    cnae_desc:
      'Concessionárias  de  rodovias,  pontes,  túneis  e serviços relacionados',
    item: '22.01',
    item_desc:
      'Serviços  de  exploração  de  rodovia  mediante  cobrança  de  preço  ou pedágio    dos    usuários,    envolvendo    execução   de    serviços   de conservação,   manutenção,   melhoramentos   para   adequação   de capacidade    e    segurança    de    trânsito,    operação,    monitoração, assistência  aos  usuários  e  outros  serviços  definidos  em  contratos, atos de concessão ou de permissão ou em normas oficiais.',
  },
  {
    cnae: '7410-2/01',
    cnae_desc: 'Design',
    item: '23.01',
    item_desc:
      'Serviços de programação e comunicação visual, desenho industrial e congêneres.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '23.01',
    item_desc:
      'Serviços de programação e comunicação visual, desenho industrial e congêneres.',
  },
  {
    cnae: '4329-1/01',
    cnae_desc: 'Instalação de painéis publicitários',
    item: '24.01',
    item_desc:
      'Serviços  de  chaveiros,  confecção  de  carimbos,  placas,  sinalização visual, banners, adesivos e congêneres.',
  },
  {
    cnae: '8299-7/03',
    cnae_desc: 'Serviços   de   gravação   de   carimbos,   exceto confecção',
    item: '24.01',
    item_desc:
      'Serviços  de  chaveiros,  confecção  de  carimbos,  placas,  sinalização visual, banners, adesivos e congêneres.',
  },
  {
    cnae: '9529-1/02',
    cnae_desc: 'Chaveiros',
    item: '24.01',
    item_desc:
      'Serviços  de  chaveiros,  confecção  de  carimbos,  placas,  sinalização visual, banners, adesivos e congêneres.',
  },
  {
    cnae: '3299-0/03',
    cnae_desc:
      'Fabricação   de   letras,   letreiros   e   placas   de qualquer material, exceto luminosos',
    item: '24.01',
    item_desc:
      'Serviços  de  chaveiros,  confecção  de  carimbos,  placas,  sinalização visual, banners, adesivos e congêneres.',
  },
  {
    cnae: '3299-0/04',
    cnae_desc: 'Fabricação de painéis e letreiros luminosos',
    item: '24.01',
    item_desc:
      'Serviços  de  chaveiros,  confecção  de  carimbos,  placas,  sinalização visual, banners, adesivos e congêneres.',
  },
  {
    cnae: '9603-3/03',
    cnae_desc: 'Serviços de sepultamento',
    item: '25.01',
    item_desc:
      'Funerais, inclusive fornecimento de caixão, urna ou esquifes; aluguel de  capela;  transporte  do  corpo  cadavérico;  fornecimento  de  flores, coroas  e  outros  paramentos;  desembaraço  de  certidão  de  óbito; fornecimento   de   véu,   essa   e   outros   adornos;   embalsamento, embelezamento, conservação ou restauração de cadáveres.',
  },
  {
    cnae: '9603-3/04',
    cnae_desc: 'Serviços de funerárias',
    item: '25.01',
    item_desc:
      'Funerais, inclusive fornecimento de caixão, urna ou esquifes; aluguel de  capela;  transporte  do  corpo  cadavérico;  fornecimento  de  flores, coroas  e  outros  paramentos;  desembaraço  de  certidão  de  óbito; fornecimento   de   véu,   essa   e   outros   adornos;   embalsamento, embelezamento, conservação ou restauração de cadáveres.',
  },
  {
    cnae: '9603-3/05',
    cnae_desc: 'Serviços de somatoconservação',
    item: '25.01',
    item_desc:
      'Funerais, inclusive fornecimento de caixão, urna ou esquifes; aluguel de  capela;  transporte  do  corpo  cadavérico;  fornecimento  de  flores, coroas  e  outros  paramentos;  desembaraço  de  certidão  de  óbito; fornecimento   de   véu,   essa   e   outros   adornos;   embalsamento, embelezamento, conservação ou restauração de cadáveres.',
  },
  {
    cnae: '9603-3/99',
    cnae_desc:
      'Atividades   funerárias   e   serviços   relacionados não especificados anteriormente',
    item: '25.01',
    item_desc:
      'Funerais, inclusive fornecimento de caixão, urna ou esquifes; aluguel de  capela;  transporte  do  corpo  cadavérico;  fornecimento  de  flores, coroas  e  outros  paramentos;  desembaraço  de  certidão  de  óbito; fornecimento   de   véu,   essa   e   outros   adornos;   embalsamento, embelezamento, conservação ou restauração de cadáveres.',
  },
  {
    cnae: '9603-3/02',
    cnae_desc: 'Serviços de cremação',
    item: '25.02',
    item_desc: 'Cremação de corpos e partes de corpos cadavéricos.',
  },
  {
    cnae: '6511-1/02',
    cnae_desc: 'Planos de auxílio-funeral',
    item: '25.03',
    item_desc: 'Planos ou convênio funerários.',
  },
  {
    cnae: '9603-3/01',
    cnae_desc: 'Gestão e manutenção de cemitérios',
    item: '25.04',
    item_desc: 'Manutenção e conservação de jazigos e cemitérios.',
  },
  {
    cnae: '5310-5/01',
    cnae_desc: 'Atividades do Correio Nacional',
    item: '26.01',
    item_desc:
      'Serviços   de   coleta,   remessa   ou   entrega   de   correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres.',
  },
  {
    cnae: '5310-5/02',
    cnae_desc: 'Atividades de franqueadas do Correio Nacional',
    item: '26.01',
    item_desc:
      'Serviços   de   coleta,   remessa   ou   entrega   de   correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres.',
  },
  {
    cnae: '5320-2/01',
    cnae_desc: 'Serviços de malote não realizados pelo Correio Nacional',
    item: '26.01',
    item_desc:
      'Serviços   de   coleta,   remessa   ou   entrega   de   correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres.',
  },
  {
    cnae: '5320-2/02',
    cnae_desc: 'Serviços de entrega rápida',
    item: '26.01',
    item_desc:
      'Serviços   de   coleta,   remessa   ou   entrega   de   correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres.',
  },
  {
    cnae: '8012-9/00',
    cnae_desc: 'Atividades de transporte de valores',
    item: '26.01',
    item_desc:
      'Serviços   de   coleta,   remessa   ou   entrega   de   correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres.',
  },
  {
    cnae: '8800-6/00',
    cnae_desc: 'Serviços de assistência social sem alojamento',
    item: '27.01',
    item_desc: 'Serviços de assistência social.',
  },
  {
    cnae: '6621-5/01',
    cnae_desc: 'Peritos e avaliadores de seguros',
    item: '28.01',
    item_desc: 'Serviços de avaliação de bens e serviços de qualquer natureza.',
  },
  {
    cnae: '6821-8/01',
    cnae_desc: 'Corretagem na compra e venda e avaliação de imóveis',
    item: '28.01',
    item_desc: 'Serviços de avaliação de bens e serviços de qualquer natureza.',
  },
  {
    cnae: '7112-0/00',
    cnae_desc: 'Serviços de engenharia',
    item: '28.01',
    item_desc: 'Serviços de avaliação de bens e serviços de qualquer natureza.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '28.01',
    item_desc: 'Serviços de avaliação de bens e serviços de qualquer natureza.',
  },
  {
    cnae: '9101-5/00',
    cnae_desc: 'Atividades de bibliotecas e arquivos',
    item: '29.01',
    item_desc: 'Serviços de biblioteconomia.',
  },
  {
    cnae: '7740-3/00',
    cnae_desc: 'Gestão de ativos intangíveis não-financeiros',
    item: '03.02',
    item_desc: 'Cessão de direito de uso de marcas e de sinais de propaganda.',
  },
  {
    cnae: '9311-5/00',
    cnae_desc: 'Gestão de instalações de esportes',
    item: '03.03',
    item_desc:
      'Exploração  de  salões  de  festas,  centro  de  convenções,  escritórios virtuais,  stands,  quadras  esportivas,  estádios,  auditórios,  casas  de espetáculos,  parques  de  diversões,  canchas  e  congêneres,  para realização de eventos ou negócios de qualquer natureza',
  },
  {
    cnae: '8640-2/02',
    cnae_desc: 'Laboratórios clínicos',
    item: '30.01',
    item_desc: 'Serviços de biologia, biotecnologia e química.',
  },
  {
    cnae: '7210-0/00',
    cnae_desc:
      'Pesquisa  e  desenvolvimento  experimental  em ciências físicas e naturais',
    item: '30.01',
    item_desc: 'Serviços de biologia, biotecnologia e química.',
  },
  {
    cnae: '7119-7/99',
    cnae_desc:
      'Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente',
    item: '31.01',
    item_desc:
      'Serviços técnicos em edificações, eletrônica, eletrotécnica, mecânica, telecomunicações e congêneres.',
  },
  {
    cnae: '7119-7/03',
    cnae_desc:
      'Serviços   de   desenho   técnico   relacionados   à arquitetura e engenharia',
    item: '32.01',
    item_desc: 'Serviços de desenhos técnicos.',
  },
  {
    cnae: '5250-8/01',
    cnae_desc: 'Comissaria de despachos',
    item: '33.01',
    item_desc:
      'Serviços  de  desembaraço  aduaneiro,  comissários,  despachantes  e congêneres.',
  },
  {
    cnae: '5250-8/02',
    cnae_desc: 'Atividades de despachantes aduaneiros',
    item: '33.01',
    item_desc:
      'Serviços  de  desembaraço  aduaneiro,  comissários,  despachantes  e congêneres.',
  },
  {
    cnae: '8030-7/00',
    cnae_desc: 'Atividades de investigação particular',
    item: '34.01',
    item_desc:
      'Serviços de investigações particulares, detetives e congêneres.',
  },
  {
    cnae: '9002-7/01',
    cnae_desc:
      'Atividades    de    artistas    plásticos,    jornalistas independentes e escritores',
    item: '35.01',
    item_desc:
      'Serviços   de   reportagem,   assessoria   de   imprensa,   jornalismo   e relações públicas.',
  },
  {
    cnae: '7020-4/00',
    cnae_desc:
      'Atividades      de      consultoria      em      gestão empresarial,      exceto      consultoria      técnica específica',
    item: '35.01',
    item_desc:
      'Serviços   de   reportagem,   assessoria   de   imprensa,   jornalismo   e relações públicas.',
  },
  {
    cnae: '7490-1/99',
    cnae_desc:
      'Outras   atividades   profissionais,   científicas   e técnicas não especificadas anteriormente',
    item: '36.01',
    item_desc: 'Serviços de meteorologia.',
  },
  {
    cnae: '7490-1/05',
    cnae_desc:
      'Agenciamento  de  profissionais  para  atividades esportivas, culturais e artísticas',
    item: '37.01',
    item_desc: 'Serviços de artistas, atletas, modelos e manequins.',
  },
  {
    cnae: '9102-3/01',
    cnae_desc:
      'Atividades   de   museus   e   de   exploração   de lugares e prédios históricos e atrações similares',
    item: '38.01',
    item_desc: 'Serviços de museologia.',
  },
  {
    cnae: '3211-6/01',
    cnae_desc: 'Lapidação de gemas',
    item: '39.01',
    item_desc:
      'Serviços de ourivesaria e lapidação (quando o material for fornecido pelo tomador do serviço).',
  },
  {
    cnae: '7410-2/01',
    cnae_desc: 'Design',
    item: '39.01',
    item_desc:
      'Serviços de ourivesaria e lapidação (quando o material for fornecido pelo tomador do serviço).',
  },
  {
    cnae: '9002-7/01',
    cnae_desc:
      'Atividades    de    artistas    plásticos,    jornalistas independentes e escritores',
    item: '40.01',
    item_desc: 'Obras de arte sob encomenda.',
  },
]
