import { useState } from 'react'
import CopyBtn from './CopyBtn'
import { set } from 'react-ga'

export default function Cnae({ code }) {
  const [showAlert, setShowAlert] = useState(false)

  const copy = async () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(code.item)
      setShowAlert(true)
      msgTimeout()
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = code.item
      textArea.style.position = 'absolute'
      textArea.style.left = '-999999px'
      document.body.prepend(textArea)
      textArea.select()
      try {
        document.execCommand('copy')
        setShowAlert(true)
        msgTimeout()
      } catch (err) {
        console.error(err)
      } finally {
        textArea.remove()
      }
    }
  }

  const msgTimeout = () => {
    setTimeout(() => {
      setShowAlert(false)
    }, 2000)
  }

  return (
    <div className="cnae-grid">
      <div className="cnae-code">
        <h3>{code.cnae}</h3>
      </div>
      <div>
        <p>{code.cnae_desc}</p>
      </div>
      <div className="item-code">
        <CopyBtn onClick={copy} />
        <h3>{code.item}</h3>
      </div>
      <div>
        <p>{code.item_desc}</p>
      </div>
      <div className={showAlert ? 'alert' : 'off'}>
        Código de Tributação copiado
      </div>
    </div>
  )
}
