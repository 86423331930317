import { useState } from 'react'
import { cnae_codes } from '../utils/Codes'
import { cnae_codes_exclude } from '../utils/CodesToExclude'
import Cnae from './Cnae'
import ExcludedCnae from './ExcludedCnae'

export default function SearchBar() {
  const [text, setText] = useState('')
  const [filteredCodes, setFilteredCodes] = useState([])
  const [filteredExcludeCode, setFilteredExcludeCodes] = useState()

  //Filter service codes
  const filterCodes = searchCode => {
    clearSearch()

    if (searchCode === '') {
      return clearSearch()
    }

    if (searchCode) {
      cnae_codes.forEach(cnae_code => {
        if (cnae_code.cnae.includes(searchCode)) {
          setFilteredCodes(prev => [...prev, cnae_code])
        }
      })

      setText(searchCode)
    }

    if (searchCode && filteredCodes.length === 0) {
      // console.log(searchCode.slice(0, 4))
      // if (
      //   cnae_codes_exclude.find(
      //     cnae_code_exclude => cnae_code_exclude.cnae === searchCode.slice(0, 4)
      //   )
      // ) {
      //   setFilteredExcludeCodes(prev => [...prev, searchCode])
      // }
      cnae_codes_exclude.forEach(cnae_code_exclude => {
        if (cnae_code_exclude.cnae === searchCode.slice(0, 4)) {
          console.log(cnae_code_exclude.cnae)
          console.log(searchCode.slice(0, 4))
          // if (cnae_code_exclude.cnae.match(/^(\d{4})/)) {
          return setFilteredExcludeCodes(cnae_code_exclude)
          // }
        }
      })
      console.log(filteredExcludeCode)
      setText(searchCode)
    }
  }

  const clearSearch = () => {
    setFilteredCodes([])
    setText('')
  }

  //Format a input before send
  const format = search => {
    let formatted = search.replace(/\D/g, '')
    // formatted = formatted.replace(/^(\d{4})(\d{1})(\d{2})/g, '$1-$2/$3')
    formatted = formatted.replace(/^(\d{4})/g, '$1-')
    formatted = formatted.replace(/^(\d{4}-\d{1})/g, '$1/')
    setText(formatted)
    return formatted
  }

  const erase = e => {
    let erased = text
    if (e.key === 'Backspace') {
      //   clearSearch()
      //   erased = text.slice(0, text.length - 1)
      erased = text.replace(/.$/, '')
      setText(erased)
    }
  }

  return (
    <div>
      <form>
        <label htmlFor="search">Digite o código CNAE</label>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="0000-0/00"
          maxLength={9}
          value={text}
          onChange={e => filterCodes(format(e.target.value))}
          onKeyUp={erase}
        />
      </form>
      <div className="cnae-container">
        {filteredCodes.length > 0 ? (
          filteredCodes
            .sort((a, b) => a.value - b.value)
            .slice(0, 7)
            .map((code, key) => <Cnae key={key} code={code} />)
        ) : filteredExcludeCode ? (
          <ExcludedCnae code={filteredExcludeCode} />
        ) : (
          // <div className="error-cnae">Código não é de serviço</div>
          <div className="error-cnae">Insira um código CNAE válido</div>
        )}
      </div>
    </div>
  )
}

// filteredExcludeCodes
//             .slice(0, 7)
//             .map((code, key) =>
