import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'

export default function CopyBtn({ onClick }) {
  const [isCopied, setIsCopied] = useState(false)

  const copied = () => {
    onClick()
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <div className="copy-btn">
      <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} onClick={copied} />
    </div>
  )
}
