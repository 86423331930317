import './App.css'
import SearchBar from './components/SearchBar'
// import Ads from './components/Ads'
import logo from './assets/logo.png'

function App() {
  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <a href="https://facilitamei.online">
            <img src={logo} alt="Logo Facilita MEI" width={350} />
            <h3>facilitamei.online</h3>
          </a>
        </div>
      </header>
      <div className="container">
        {/* <Ads adSlot="2645967379" /> */}
        <div className="intro">
          <h2>
            Aqui você encontra os Códigos de Tributação Nacional utilizados no{' '}
            <a
              href="https://www.nfse.gov.br/EmissorNacional"
              target="_blank"
              rel="noopener noreferrer"
            >
              Emissor Nacional
            </a>{' '}
            de NFS-e referentes aos CNAEs (Classificação Nacional de Atividades
            Econômicas) que são exercidas por uma Empresa ou MEI.
          </h2>
        </div>
        <SearchBar />
      </div>
      <footer>
        <p>
          Todos os direitos reservados -{' '}
          <a href="https://facilitamei.online">facilitamei.online</a> ©
          {new Date().getFullYear()}
        </p>
        <p>
          Desenvolvido por{' '}
          <a href="https://github.com/Gontijoweb">Vinicius Gontijo</a>
        </p>
      </footer>
    </div>
  )
}

export default App
