import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ReactGa from 'react-ga4'

ReactGa.initialize('G-PL3E09M89E')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
